import {useState} from 'react';

// This hook is used to handle logic for visit dates/times
const useVisitDateTime = () => {
  const now = new Date();
  const nowDateFormatted = now.toISOString().split('T')[0];
  const nowTimeFormatted = now.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
  const [newVisitDate, setNewVisitDate] = useState(nowDateFormatted);
  const [newVisitTime, setNewVisitTime] = useState(nowTimeFormatted);
  const isToday = newVisitDate === nowDateFormatted;
  const previousDateWasEntered = new Date(newVisitDate) < new Date(nowDateFormatted);
  const handleDateChange = () => {
    // When the date changes, reset just the time portion
    const now = new Date();
    setNewVisitTime(now.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}));
  };
  return {
    newVisitDate,
    // provide a min value for date inputs (today's date)
    minVisitDate: nowDateFormatted,
    setNewVisitDate,
    newVisitTime,
    minVisitTime: isToday ? nowTimeFormatted : undefined,
    setNewVisitTime,
    previousDateWasEntered,
    handleDateChange,
    reset: () => {
      const now = new Date();
      setNewVisitDate(now.toISOString().split('T')[0]);
      setNewVisitTime(now.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}));
    },
  };
};

export default useVisitDateTime;
