import {createContext, useContext} from 'react';
import {useAuth} from './WebAppAuthProvider';

const VenueGroupContext = createContext({});

const VenueGroupProvider = ({cms = false, children}) => {
  const {AUTHENTICATED_HEADERS, handleUnauthorized} = useAuth();

  const fetchGroups = async (page = 1, searchTerm = '') => {
    const query = new URLSearchParams({
      page,
      // we can send searchString with every request because the API
      // will treat '' as empty
      search_string: searchTerm,
    }).toString();

    const response = await fetch(`/api/cms/groups?${query}`, {
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't retrieve groups");
        console.error('Failed to fetch groups', response);
      }
    }
  };

  const fetchGroup = async (id) => {
    const response = await fetch(cms ? `/api/cms/groups/${id}` : `/api/groups/${id}`, {
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't retrieve group");
        console.error('Failed to fetch group', response);
      }
    }
  };

  const createGroup = async (name, venueIds = [], organisation_id) => {
    const response = await fetch(`/api/cms/groups`, {
      method: 'POST',
      headers: AUTHENTICATED_HEADERS,
      body: JSON.stringify({name, venueIds, organisation_id}),
    });
    if (response.ok) {
      const responseJson = await response.json();
      const group = await fetchGroup(responseJson.id);
      return group;
    } else {
      alert("Couldn't create group");
      console.error('Failed to create group', response);
    }
  };

  const updateGroup = async (id, name, venueIds) => {
    const response = await fetch(`/api/cms/groups/${id}`, {
      method: 'PUT',
      headers: AUTHENTICATED_HEADERS,
      body: JSON.stringify({name, venueIds}),
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      alert("Couldn't update group");
      console.error('Failed to update group', response);
    }
  };

  const deleteGroup = async (id) => {
    const response = await fetch(`/api/cms/groups/${id}`, {
      method: 'DELETE',
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      alert("Couldn't delete group");
      console.error('Failed to delete group', response);
    }
  };

  return (
    <VenueGroupContext.Provider
      value={{
        fetchGroups,
        fetchGroup,
        createGroup,
        deleteGroup,
        updateGroup,
      }}>
      {children}
    </VenueGroupContext.Provider>
  );
};

export const useVenueGroup = () => useContext(VenueGroupContext);

export default VenueGroupProvider;
