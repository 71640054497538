import {useParams, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import {Typography} from '@mui/material';

import useVisitRedirectIfNoVenue from './useVisitRedirectIfNoVenue';
import useVenueById from './Queries/useVenueById';
import Loading from '../components/Loading';
import AnswerVisitQuestions from './components/AnswerVisitQuestions';
import BackLink from '../components/BackLink';
import {useNewVisit} from './NewVisitProvider';
import PageHeading from '../components/PageHeading';

const VisitQuestions = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: venue, isLoading} = useVenueById(id);
  const {requiredQuestionsComplete, newVisitQuestionResponses, setQuestionResponse} = useNewVisit();
  useVisitRedirectIfNoVenue();
  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <Typography variant="h3" sx={{mb: 4}}>
        Your visit to {venue.name}
      </Typography>
      <PageHeading>Questions</PageHeading>
      <AnswerVisitQuestions
        venue={venue}
        questionResponses={newVisitQuestionResponses}
        setQuestionResponse={setQuestionResponse}
      />
      <div>
        <BackLink to={`/venues/${venue.id}/arrange-visit/when`} />
        <Button
          variant="contained"
          color="primary"
          style={{margin: '10px'}}
          disabled={!requiredQuestionsComplete(venue)}
          onClick={() => {
            if (requiredQuestionsComplete(venue)) {
              navigate(`/venues/${venue.id}/arrange-visit/requirements`);
            } else {
              alert('Please answer all required questions before continuing.');
            }
          }}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default VisitQuestions;
