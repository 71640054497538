import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';

import {spaceBottom} from '../useSharedStyles';
import Loading from '../components/Loading';
import PageHeading from '../components/PageHeading';
import SearchInput from '../components/SearchInput';
import ScreenActionContainer from '../components/ScreenActionContainer';

const AdminList = ({
  fetchItems,
  title,
  titleId,
  searchPlaceholder,
  renderItems,
  headerActions,
  showSearch = true,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const urlPageNumber = searchParams.get('page') ?? 1;
  const urlSearchTerm = searchParams.get('search') ?? '';

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState({data: []});
  const [searchTerm, setSearchTerm] = useState(urlSearchTerm);

  // When the user requests a search, update URL
  const handleFetch = async (newPage, searchString) => {
    // URLSearchParams will encode undefined to "undefined", which breaks things
    const params = new URLSearchParams({
      ...(searchString?.length > 0 ? {search: searchString} : {}),
      ...(newPage > 0 ? {page: newPage + 1} : {}), // MUI's pagination starts at 0, Laravel's at 1, so add 1
    });
    setSearchParams(params);
  };

  // When the URL changes, search based on URL params
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      // If we've navigated back/forwards to this page, we need to ensure searchTerm
      // is updated based on the new URL
      setSearchTerm(urlSearchTerm);

      // Fetch results based on the URL
      const response = await fetchItems(urlPageNumber, urlSearchTerm);
      if (response.current_page > response.last_page) {
        // If page number was out of range, reset to zero
        handleFetch(0, searchTerm);
      } else {
        setItems(response);
        setIsLoading(false);
      }
    };
    init();
  }, [urlPageNumber, urlSearchTerm]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="baseline" sx={spaceBottom}>
        <PageHeading id={titleId}>{title}</PageHeading>
        {headerActions && <ScreenActionContainer>{headerActions}</ScreenActionContainer>}
      </Box>
      {showSearch && (
        <SearchInput
          placeholder={searchPlaceholder}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onSearch={() => handleFetch(0, searchTerm)}
          onClear={() => {
            setSearchTerm('');
            handleFetch(0, '');
          }}
        />
      )}
      {items.current_page && (
        <Box display="flex" justifyContent="flex-end" sx={{my: 2}}>
          <Pagination
            component="div"
            count={items.last_page}
            onChange={(_, newPage) => handleFetch(newPage - 1, searchTerm)}
            color="primary"
            page={items.current_page}
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      {isLoading ? (
        <Loading />
      ) : items.data.length === 0 ? (
        <div>No results found.</div>
      ) : (
        renderItems(items)
      )}
    </>
  );
};

export default AdminList;
