const uploadFile = async (token, file) => {
  const fd = new FormData();
  fd.append('file', file);
  const result = await fetch('/api/cms/files', {
    body: fd,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result;
};
export default uploadFile;
