import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useHelpLinks = () =>
  useQuery({
    queryKey: ['help-links'],
    queryFn: async () => await axios.get('/api/help-links').then((res) => res.data.data),
    staleTime: Infinity,
  });

export default useHelpLinks;
