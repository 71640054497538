import {createContext, useContext} from 'react';
import {useAuth} from './WebAppAuthProvider';

const UserContext = createContext({});

const UserProvider = ({children}) => {
  const {AUTHENTICATED_HEADERS, handleUnauthorized, token} = useAuth();

  const fetchUsers = async (page = 1, searchTerm = '') => {
    const query = new URLSearchParams({
      page,
      // we can send searchString with every request because the API
      // will treat '' as empty
      search_string: searchTerm,
    }).toString();
    const response = await fetch(`/api/cms/users?${query}`, {
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't retrieve users");
        console.error('Failed to fetch users', response);
      }
    }
  };

  const fetchUser = async (id) => {
    const response = await fetch(`/api/cms/users/${id}`, {headers: AUTHENTICATED_HEADERS});
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't retrieve user");
        console.error('Failed to fetch user', response);
      }
    }
  };

  const updateUserProfilePicture = async (id, profilePicture) => {
    const formData = new FormData();
    formData.append('profile_picture', profilePicture);
    const profilePictureResponse = await fetch(`/api/cms/users/${id}/profile-picture`, {
      method: 'post',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!profilePictureResponse.ok) {
      alert('Your profile picture could not be updated at this time. Please try again later.');
    }
  };

  const updateUser = async (id, user, profilePicture) => {
    const {
      name,
      phone,
      email,
      disabilities = [],
      requirements = [],
      organisation_id,
      organisation_notification_settings,
      venue_group_id,
      venue_group_notification_settings,
      store_id,
      venue_notification_settings,
      role,
    } = user;

    const response = await fetch(`/api/cms/users/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        name,
        phone,
        email,
        requirements: requirements.map(({id}) => id),
        disabilities: disabilities.map(({id}) => id),
        organisation_id,
        organisation_notification_settings,
        venue_group_id,
        venue_group_notification_settings,
        store_id,
        venue_notification_settings,
        role,
      }),
      headers: AUTHENTICATED_HEADERS,
    });

    if (response.ok) {
      // If a new profile picture is set, update the it in a separate request
      // (this endpoint requires FormData and a post request with specific formData)
      if (profilePicture) {
        await updateUserProfilePicture(id, profilePicture);
      }

      const user = await fetchUser(id);
      return user;
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't update user - please refresh and try again");
        console.error('Failed to update user', errors);
      }
    }
  };

  const createUser = async (user, profilePicture) => {
    const {
      name,
      phone,
      email,
      disabilities,
      requirements,
      organisation_id,
      organisation_notification_settings,
      venue_group_id,
      venue_group_notification_settings,
      store_id,
      venue_notification_settings,
      role,
    } = user;

    const response = await fetch(`/api/cms/users/`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        phone,
        email,
        requirements: requirements.map(({id}) => id),
        disabilities: disabilities.map(({id}) => id),
        organisation_id,
        organisation_notification_settings,
        venue_group_id,
        venue_group_notification_settings,
        store_id,
        venue_notification_settings,
        role: role.name,
      }),
      headers: AUTHENTICATED_HEADERS,
    });

    if (response.ok) {
      const newUser = await response.json();
      // If a new profile picture is set, update the it in a separate request
      // (this endpoint requires FormData and a post request with specific formData)
      if (profilePicture) {
        await updateUserProfilePicture(newUser.id, profilePicture);
      }

      const user = await fetchUser(newUser.id);
      return user;
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't create user - please refresh and try again");
        console.error('Failed to create user', errors);
      }
    }
  };

  const deleteUser = async (id) => {
    const response = await fetch(`/api/cms/users/${id}`, {
      method: 'DELETE',
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      return true;
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't delete user - please refresh and try again");
        console.error('Failed to delete user', errors);
      }
    }
  };

  return (
    <UserContext.Provider value={{fetchUsers, fetchUser, updateUser, createUser, deleteUser}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUsers = () => useContext(UserContext);

export default UserProvider;
