import {Box} from '@mui/system';
import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import {MuiTelInput} from 'mui-tel-input';
import DeleteIcon from '@mui/icons-material/Delete';

import PageBackgroundHeader from './PageBackgroundHeader';
import {multiline, spaceBottom, spaceBottomSmall} from '../useSharedStyles';
import PageHeading from './PageHeading';
import ScreenActionContainer from './ScreenActionContainer';
import ProfilePicture from './ProfilePicture';
import FileDrop from './FileDrop';
import SectionHeading from './SectionHeading';
import VenueListItem from './VenueListItem';
import {useAuth} from '../context/WebAppAuthProvider';
import AdminAudit from '../AdminWebApp/AdminAudit';
import RequirementsList from './RequirementsList';
import DisabilitiesList from './DisabilitiesList';
import AdminVenueList from '../AdminWebApp/AdminVenueList';
import {useRoles} from '../context/RoleProvider';
import GroupListItem from './GroupListItem';
import AdminVenueGroupList from '../AdminWebApp/AdminVenueGroupList';
import AssignedOrganisation from './AssignedOrganisation';
import {CMSUserNotificationSettings} from './CMSUserNotificationSettings';

const UserOrgSettings = ({user, setUser, isEditing}) => {
  const notificationSettings = user.organisation_notification_settings;
  const handleSettingUpdate = (settingKey, newValue) => {
    notificationSettings[settingKey] = newValue;
    setUser({
      ...user,
      organisation_notification_settings: notificationSettings,
    });
  };
  return (
    <>
      <AssignedOrganisation
        organisation={user.organisation}
        setOrganisation={(organisation) => {
          setUser({...user, organisation, organisation_id: organisation.id});
        }}
        isEditing={isEditing}
      />
      <Box sx={spaceBottom}>
        <SectionHeading>Organisation Notification Settings</SectionHeading>
        <CMSUserNotificationSettings
          notificationSettings={user.organisation_notification_settings}
          handleSettingUpdate={handleSettingUpdate}
          isEditing={isEditing}
        />
      </Box>
    </>
  );
};

const UserVenueSettings = ({user, setUser, isEditing}) => {
  const [editIsOpen, setEditIsOpen] = useState(false);
  // TODO - there should only be 1 set of settings per user
  const notificationSettings = user.venue_notification_settings[0];
  const handleSettingUpdate = (settingKey, newValue) => {
    notificationSettings[settingKey] = newValue;
    setUser({
      ...user,
      venue_notification_settings: [notificationSettings],
    });
  };
  return (
    <>
      <Box sx={spaceBottom}>
        <SectionHeading
          button={
            isEditing && (
              <Button variant="outlined" onClick={() => setEditIsOpen(true)}>
                Change Venue
              </Button>
            )
          }>
          Assigned Venue
        </SectionHeading>
        {editIsOpen && (
          <Dialog
            open
            onClose={() => setEditIsOpen(false)}
            fullWidth
            aria-labelledby="admin-venue-list-title">
            <DialogContent>
              <AdminVenueList
                onClick={(venue) => {
                  setUser({...user, store: venue, store_id: venue.id});
                  setEditIsOpen(false);
                }}
              />
            </DialogContent>
          </Dialog>
        )}
        <List>
          {user.store ? (
            <VenueListItem
              venue={user.store}
              onClick={isEditing ? () => setEditIsOpen(true) : undefined}
            />
          ) : (
            <ListItem>
              <ListItemText primary="No Store selected" />
            </ListItem>
          )}
        </List>
      </Box>
      <Box sx={spaceBottom}>
        <SectionHeading>Venue Notification Settings</SectionHeading>
        <CMSUserNotificationSettings
          notificationSettings={notificationSettings}
          handleSettingUpdate={handleSettingUpdate}
          isEditing={isEditing}
        />
      </Box>
    </>
  );
};

const UserGroupSettings = ({user, setUser, isEditing}) => {
  const navigate = useNavigate();
  const [editIsOpen, setEditIsOpen] = useState(false);
  const notificationSettings = user.venue_group_notification_settings;
  const handleSettingUpdate = (settingKey, newValue) => {
    notificationSettings[settingKey] = newValue;
    setUser({
      ...user,
      venue_group_notification_settings: notificationSettings,
    });
  };
  return (
    <>
      <Box sx={spaceBottom}>
        <SectionHeading
          button={
            isEditing && (
              // TODO - disable if we are in the create group admin dialog
              <Button variant="outlined" onClick={() => setEditIsOpen(true)}>
                Change Group
              </Button>
            )
          }>
          Assigned Group
        </SectionHeading>
        {editIsOpen && (
          <Dialog
            open
            onClose={() => setEditIsOpen(false)}
            fullWidth
            aria-labelledby="admin-venue-group-list-title">
            <DialogContent>
              <AdminVenueGroupList
                onClick={(venue_group) => {
                  setUser({...user, venue_group, venue_group_id: venue_group.id});
                  setEditIsOpen(false);
                }}
              />
            </DialogContent>
          </Dialog>
        )}
        <List>
          {user.venue_group ? (
            <GroupListItem
              group={user.venue_group}
              onClick={
                isEditing ? undefined : () => navigate(`/venue-groups/${user.venue_group.id}`)
              }
              showOrgName
            />
          ) : (
            <ListItem>
              <ListItemText primary="No Group selected" />
            </ListItem>
          )}
        </List>
      </Box>
      <Box sx={spaceBottom}>
        <SectionHeading>Group Notification Settings</SectionHeading>
        <CMSUserNotificationSettings
          notificationSettings={notificationSettings}
          handleSettingUpdate={handleSettingUpdate}
          isEditing={isEditing}
        />
      </Box>
    </>
  );
};

const UserDetailsForm = ({
  title = 'User',
  user,
  audit,
  setUser,
  handleSave,
  handleCancel,
  handleDeleteUser,
  initialIsEditing,
  disableRole = false,
}) => {
  const {cmsType} = useAuth();
  const [selectedTab, setSelectedTab] = useState('general');
  const [isEditing, setIsEditing] = useState(initialIsEditing);

  const theme = useTheme();

  const {allRoles} = useRoles();
  // The backend sends down roles, but the update endpoint expects role
  const userRole = user.role ?? user.roles?.[0];
  const roleName = userRole?.name;

  const [newProfilePicture, setNewProfilePicture] = useState();
  useEffect(() => setNewProfilePicture(undefined), [isEditing]);

  // if cmsType is admin and the user exists, show the history tab
  const shouldShowHistory = cmsType === 'admin' && user.id;

  const handleRoleChange = (newRoleName) => {
    const role = allRoles.find(({name}) => name === newRoleName);
    // Used to clear existing org/group/venue settings
    const clear = {
      organisation_id: null,
      organisation: null,
      organisation_notification_settings: null,
      venue_group_id: null,
      venue_group: null,
      venue_group_notification_settings: null,
      store_id: null,
      store: null,
      venue_notification_settings: [],
    };

    const defaultNotificationSettings = {
      email_notifications: false,
      phone_booking: false,
      phone_arrived: false,
    };

    // Only set diff if the user's role has changed
    let diff = {};
    if (roleName !== 'organisation-admin' && role.name === 'organisation-admin') {
      diff = {
        ...clear,
        organisation_notification_settings: {
          ...defaultNotificationSettings,
        },
      };
    } else if (roleName !== 'venue-group-admin' && role.name === 'venue-group-admin') {
      diff = {
        ...clear,
        venue_group_notification_settings: {
          ...defaultNotificationSettings,
        },
      };
    } else if (
      // Note: avoids clearing settings if changing between store-admin/store-user
      roleName !== 'store-admin' &&
      roleName !== 'store-user' &&
      (role.name === 'store-admin' || role.name === 'store-user')
    ) {
      diff = {
        ...clear,
        venue_notification_settings: [
          {
            ...defaultNotificationSettings,
          },
        ],
      };
    }
    setUser({
      ...user,
      role,
      roles: [role],
      ...diff,
    });
  };

  const validateAndSave = async () => {
    let venueRoleValid = true;
    let groupRoleValid = true;
    let organisationRoleValid = true;
    let adminRoleValid = true;
    // Store users need a store
    if (roleName === 'store-admin' || roleName === 'store-user') {
      if (!user.store_id) {
        venueRoleValid = false;
      }
    } else if (roleName === 'venue-group-admin') {
      if (!user.venue_group_id) {
        groupRoleValid = false;
      }
    } else if (roleName === 'organisation-admin') {
      if (!user.organisation_id) {
        organisationRoleValid = false;
      }
    } else if (roleName === 'admin') {
      if (!!user.store_id || !!user.organisation_id) {
        adminRoleValid = false;
      }
      if (initialIsEditing) {
        // If this component was mounted in Edit mode, we are creating a new user
        // It's not possible to create admin users via the CMS
        adminRoleValid = false;
      }
    }
    if (venueRoleValid && groupRoleValid && organisationRoleValid && adminRoleValid) {
      await handleSave(newProfilePicture);
      setIsEditing(false);
    } else {
      if (!venueRoleValid) {
        alert('Please ensure a venue and notification settings exist');
      } else if (!groupRoleValid) {
        alert('Please ensure a group and notification settings exist');
      } else if (!organisationRoleValid) {
        alert('Please ensure an organistaion and notification settings exist');
      } else if (!adminRoleValid) {
        alert('Admin users cannot be created via the CMS');
      }
    }
  };

  const actions = !isEditing ? (
    <>
      <Button
        disabled={roleName === 'admin'}
        variant="contained"
        color="secondary"
        endIcon={<DeleteIcon />}
        onClick={async () => {
          if (
            window.confirm(
              `Are you sure you want to ${roleName !== 'user' ? 'hard ' : ''}delete this user?`,
            )
          ) {
            await handleDeleteUser();
          }
        }}>
        {roleName === 'user' ? 'Delete' : 'Hard Delete'}
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsEditing(true)}
        style={{marginLeft: theme.spacing(1)}}>
        Edit
      </Button>
    </>
  ) : (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={async () => {
          const confirmCancel =
            window.confirm(
              "Are you sure you want to cancel? You'll lose any changes you've made.",
            ) === true;
          if (confirmCancel) {
            await handleCancel();
            setIsEditing(false);
          }
        }}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          await validateAndSave();
        }}
        style={{marginLeft: theme.spacing(1)}}>
        Save
      </Button>
    </>
  );

  return (
    <>
      <PageBackgroundHeader />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        sx={spaceBottomSmall}>
        <PageHeading id="user-details-form-title">{title}</PageHeading>
        <ScreenActionContainer>
          <div>{actions}</div>
        </ScreenActionContainer>
      </Box>
      {shouldShowHistory && (
        <Tabs
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
          indicatorColor="primary"
          textColor="inherit"
          scrollButtons="auto"
          variant="scrollable">
          <Tab label="General Info" value="general" />
          <Tab label="History" value="history" />
        </Tabs>
      )}
      <Card variant="fullscreen">
        {selectedTab === 'general' ? (
          <CardContent>
            <Box display="flex" sx={{flexDirection: {xs: 'column', md: 'row'}, gap: 4}}>
              <Box sx={{flex: 1}}>
                {!isEditing ? (
                  <Typography variant="h2">{user.name}</Typography>
                ) : (
                  <>
                    <Box sx={{flex: 1}}>
                      <Typography variant="h6" component="label" htmlFor="name">
                        Name
                      </Typography>
                    </Box>
                    <TextField
                      id="name"
                      name="name"
                      fullWidth
                      value={user.name}
                      inputProps={{style: {fontSize: theme.typography.h2.fontSize}}}
                      onChange={(event) => setUser({...user, name: event.target.value})}
                    />
                  </>
                )}
                <Typography
                  variant="h6"
                  component="label"
                  htmlFor="user-role"
                  id="user-role-select-label">
                  Role
                </Typography>
                {!isEditing ? (
                  <Typography variant="h4">
                    {userRole?.display_name ?? '(No role attached)'}
                  </Typography>
                ) : (
                  <Select
                    fullWidth
                    labelId="user-role-select-label"
                    inputProps={{
                      name: 'user-role',
                      id: 'user-role',
                    }}
                    value={userRole?.name ?? ''}
                    onChange={(event) => {
                      handleRoleChange(event.target.value);
                    }}
                    // Admins/Users cannot have their role changed
                    disabled={
                      disableRole ||
                      (user.id && (userRole?.name === 'admin' || userRole?.name === 'user'))
                    }>
                    {allRoles.map((role) => (
                      <MenuItem
                        value={role.name}
                        key={role.id}
                        // Admins can't be created
                        // Existing users' roles can't be changed to Admins/Users
                        disabled={role.name === 'admin' || (user.id && role.name === 'user')}>
                        {role.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: {xs: 'flex-start', md: 'center'},
                }}>
                {!isEditing ? (
                  user.profile_picture && (
                    <ProfilePicture
                      src={user.profile_picture.public_url}
                      alt={`${user.name} profile picture`}
                    />
                  )
                ) : (
                  <FileDrop
                    style={{width: 200}}
                    initialValue={user?.profile_picture?.public_url}
                    onFileUpdate={(newFile) => setNewProfilePicture(newFile)}
                  />
                )}
              </Box>
            </Box>
            <Box sx={spaceBottom}>
              <SectionHeading>Contact Details</SectionHeading>
              <Box display="flex" sx={{flexDirection: {xs: 'column', md: 'row'}, gap: 4}}>
                <Box sx={{flex: 1}}>
                  <Typography variant="h6" component="label" htmlFor="email">
                    Email
                  </Typography>
                  {!isEditing ? (
                    <Typography variant="body1" sx={{...multiline, ...spaceBottom}}>
                      {user.email}
                    </Typography>
                  ) : (
                    <TextField
                      id="email"
                      name="email"
                      fullWidth
                      value={user.email}
                      sx={spaceBottom}
                      onChange={(event) => setUser({...user, email: event.target.value})}
                    />
                  )}
                  <Typography variant="h6" component="label" htmlFor="phone">
                    Phone
                  </Typography>
                  {!isEditing ? (
                    <Typography variant="body1" sx={{...multiline, ...spaceBottom}}>
                      {user.phone}
                    </Typography>
                  ) : (
                    <MuiTelInput
                      id="phone"
                      name="phone"
                      value={user.phone}
                      onChange={(value, info) => {
                        // value doesn't include the country code, which we want
                        // so use info.numberValue
                        setUser({...user, phone: info.numberValue});
                      }}
                      forceCallingCode
                      defaultCountry="GB"
                      fullWidth
                      focusOnSelectCountry
                      continents={['EU']}
                      disableFormatting
                    />
                  )}
                </Box>

                <Box sx={{flex: 1}}>
                  <Typography variant="h6" component="label" htmlFor="phone-extension">
                    Phone Extension
                  </Typography>
                  {!isEditing ? (
                    <Typography variant="body1" sx={{...multiline, ...spaceBottom}}>
                      {user.phone_extension ?? '-'}
                    </Typography>
                  ) : (
                    <TextField
                      id="phone-extension"
                      name="phone-extension"
                      fullWidth
                      value={user.phone_extension ?? ''}
                      sx={spaceBottom}
                      onChange={(event) => setUser({...user, phone_extension: event.target.value})}
                    />
                  )}
                  <Typography variant="h6" component="label" htmlFor="phone-extension-delay">
                    Phone Extension Delay
                  </Typography>
                  {!isEditing ? (
                    <Typography variant="body1" sx={{...multiline, ...spaceBottom}}>
                      {user.phone_extension_delay ?? '-'}
                    </Typography>
                  ) : (
                    <TextField
                      id="phone-extension-delay"
                      name="phone-extension-delay"
                      fullWidth
                      value={user.phone_extension_delay ?? ''}
                      sx={spaceBottom}
                      onChange={(event) =>
                        setUser({...user, phone_extension_delay: event.target.value})
                      }
                    />
                  )}
                </Box>
              </Box>
            </Box>

            {roleName === 'user' ? (
              <Box display="flex" sx={{flexDirection: {xs: 'column', md: 'row'}, gap: 4}}>
                <Box sx={{flex: 1}}>
                  <SectionHeading>Disabilities</SectionHeading>
                  {isEditing ? (
                    <DisabilitiesList
                      selectedDisabilities={user.disabilities}
                      setSelectedDisabilities={(disabilities) => setUser({...user, disabilities})}
                    />
                  ) : (
                    <List>
                      {user.disabilities.map((disability) => (
                        <ListItem key={disability.id} disablePadding>
                          <ListItemButton component={Link} to={`/disabilities/${disability.id}`}>
                            <ListItemText primary={disability.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Box>
                <Box sx={{flex: 1}}>
                  <SectionHeading>Requirements</SectionHeading>
                  {isEditing ? (
                    <RequirementsList
                      selectedRequirements={user.requirements}
                      setSelectedRequirements={(requirements) => setUser({...user, requirements})}
                    />
                  ) : (
                    <List>
                      {user.requirements.map((requirement) => (
                        <ListItem key={requirement.id}>
                          <ListItemButton component={Link} to={`/features/${requirement.id}`}>
                            <ListItemText primary={requirement.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Box>
              </Box>
            ) : roleName === 'organisation-admin' ? (
              <UserOrgSettings user={user} setUser={setUser} isEditing={isEditing} />
            ) : roleName === 'venue-group-admin' ? (
              <UserGroupSettings user={user} setUser={setUser} isEditing={isEditing} />
            ) : roleName === 'store-admin' || roleName === 'store-user' ? (
              <UserVenueSettings user={user} setUser={setUser} isEditing={isEditing} />
            ) : null}
          </CardContent>
        ) : selectedTab === 'history' ? (
          <CardContent>
            <SectionHeading>History</SectionHeading>
            <AdminAudit audit={audit} />
          </CardContent>
        ) : null}
      </Card>
    </>
  );
};

export default UserDetailsForm;
