import {useNavigate, useParams} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import useVisitRedirectIfNoVenue from './useVisitRedirectIfNoVenue';
import Loading from '../components/Loading';
import useVenueById from './Queries/useVenueById';

const VisitSuccess = () => {
  const {id} = useParams();
  const {data: venue, isLoading} = useVenueById(id);
  const navigate = useNavigate();

  useVisitRedirectIfNoVenue();
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Typography variant="h2">Success!</Typography>
      <Typography variant="subtitle1">Your visit to {venue.name} has been confirmed.</Typography>
      <Button variant="contained" color="primary" onClick={() => navigate(`/`)}>
        Done
      </Button>
    </>
  );
};
export default VisitSuccess;
