import {Table, TableBody, TableCell, TableHead, TableRow, Checkbox} from '@mui/material';

const NotificationSettingCell = ({setting, setSetting, isEditing, name}) =>
  isEditing ? (
    <TableCell>
      <Checkbox
        edge="end"
        onChange={() => setSetting(!setting)}
        checked={!!setting}
        name={name}
        id={name}
      />
    </TableCell>
  ) : (
    <TableCell data-testid={name}>{setting ? 'On' : 'Off'}</TableCell>
  );

export const CMSUserNotificationSettings = ({
  notificationSettings,
  handleSettingUpdate,
  isEditing,
}) => (
  <Table sx={{mb: 2}}>
    <TableHead>
      <TableRow>
        <TableCell sx={{fontWeight: 'bold'}} colSpan={2}>
          Email Notifications
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>
          <label htmlFor="email_notifications">All notifications</label>
        </TableCell>
        <NotificationSettingCell
          setting={notificationSettings.email_notifications}
          setSetting={(newValue) => handleSettingUpdate('email_notifications', newValue)}
          isEditing={isEditing}
          name={'email_notifications'}
        />
      </TableRow>
    </TableBody>
    <TableHead>
      <TableRow>
        <TableCell sx={{fontWeight: 'bold'}}>Phone/SMS Notifications</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>
          <label htmlFor="phone_booking">Visit Booked</label>
        </TableCell>
        <NotificationSettingCell
          setting={notificationSettings.phone_booking}
          setSetting={(newValue) => handleSettingUpdate('phone_booking', newValue)}
          isEditing={isEditing}
          name={'phone_booking'}
        />
      </TableRow>
      <TableRow>
        <TableCell>
          <label htmlFor="phone_arrived">Visit Arrived</label>
        </TableCell>
        <NotificationSettingCell
          setting={notificationSettings.phone_arrived}
          setSetting={(newValue) => handleSettingUpdate('phone_arrived', newValue)}
          isEditing={isEditing}
          name={'phone_arrived'}
        />
      </TableRow>
    </TableBody>
  </Table>
);
