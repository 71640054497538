import {Link} from 'react-router-dom';
import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import {useAuth} from '../context/WebAppAuthProvider';
import FavouriteVenueCheckbox from './FavouriteVenueCheckbox';

const Boolean = ({value}) => {
  return value ? <CheckIcon /> : <CloseIcon color="error" />;
};

const VenueListItem = ({venue, onClick}) => {
  const {cmsType} = useAuth();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  // Use a Link unless we have an explicit onClick handler
  const linkProps = onClick
    ? {
        onClick: () => onClick(venue),
      }
    : {
        component: Link,
        to: `/venues/${venue.id}`,
      };
  return (
    <ListItem disablePadding>
      <ListItemButton alignItems="flex-start" divider {...linkProps}>
        {isMd && (
          <ListItemAvatar>
            <Avatar alt={`${venue.name} logo`} src={venue.logo.public_url} />
          </ListItemAvatar>
        )}
        <ListItemText
          // If we're displaying the venue status indicators (see below)
          // avoid the ListItemText displaying behind them due to absolute positioning
          // see: https://github.com/mui/material-ui/issues/13495
          sx={cmsType === 'org' ? {paddingRight: isMd ? '124px' : '44px'} : {}}
          primary={venue.name}
          secondary={
            <Typography component="span" variant="body1">
              {`${venue.address_1}, ${venue.address_2}, ${venue.town}`}
            </Typography>
          }
        />
        {cmsType === 'user' ? (
          <ListItemSecondaryAction sx={{mr: 1}}>
            <FavouriteVenueCheckbox venue={venue} showLabel={false} />
          </ListItemSecondaryAction>
        ) : cmsType === 'org' || cmsType === 'admin' ? (
          <ListItemSecondaryAction>
            <Box display="flex" flexDirection={isMd ? 'row' : 'column'}>
              <Box
                display="flex"
                alignItems="center"
                sx={isMd ? {mr: 1} : {}}
                className="active-indicator">
                Active: <Boolean value={venue.active} />
              </Box>
              <Box display="flex" alignItems="center" className="listed-indicator">
                Listed: <Boolean value={venue.listed} />
              </Box>
            </Box>
          </ListItemSecondaryAction>
        ) : null}
      </ListItemButton>
    </ListItem>
  );
};

export default VenueListItem;
