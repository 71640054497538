import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Dot} from './Dot';

const SectionHeading = ({children, button, htmlFor}) => {
  const labelProps = htmlFor ? {component: 'label', htmlFor} : {};
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        width: '100%',
        marginBottom: 1,
        paddingTop: 1,
        paddingBottom: 1,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderColor: 'primary.pale',
      }}>
      <Typography variant="h2" {...labelProps}>
        <Dot />
        {children}
      </Typography>
      {button}
    </Box>
  );
};

export default SectionHeading;
