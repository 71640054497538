import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import '../InitSentry';
import WebApp from './WebApp';
import {AuthProvider} from '../context/WebAppAuthProvider';
import theme from '../WebAppTheme';

const container = document.getElementById('web-app');
const root = createRoot(container);
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline />
        <BrowserRouter>
          <WebApp />
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
);
