import {Paper, InputBase, IconButton, Divider} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const OrgVenueSearchInput = ({searchTerm, setSearchTerm}) => {
  const clearSearch = (event) => {
    event.preventDefault();
    setSearchTerm('');
  };
  return (
    <Paper
      component="form"
      sx={{
        py: 0.25,
        px: 0.5,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        mr: 2,
      }}>
      <InputBase
        onChange={(event) => setSearchTerm(event.target.value)}
        value={searchTerm}
        sx={{ml: 1, flex: 1}}
        placeholder="Search for a venue"
        inputProps={{'aria-label': 'search for a venue'}}
      />
      <Divider sx={{height: 28, m: 0.5}} orientation="vertical" />
      <IconButton color="primary" aria-label="clear" onClick={clearSearch}>
        <ClearIcon />
      </IconButton>
    </Paper>
  );
};

export default OrgVenueSearchInput;
