import {useState} from 'react';

import {Link, useNavigate} from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import {Chip} from '@mui/material';

import {useAuth} from '../context/WebAppAuthProvider';
import SharedAppBar from '../components/SharedAppBar';
import useUpcomingVisits from './Queries/useUpcomingVisits';

const WebAppHeader = () => {
  const {logout} = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {data: upcomingVisits} = useUpcomingVisits();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <SharedAppBar
      handleMenu={handleMenu}
      desktopChildren={
        <>
          <Button component={Link} onClick={handleClose} to="/" color="inherit">
            Venues
          </Button>
          <Button component={Link} onClick={handleClose} to="/visits" color="inherit">
            Visits{' '}
            {upcomingVisits?.length > 0 && (
              <Chip label={upcomingVisits.length} size="small" sx={{ml: 0.5}} variant="outlined" />
            )}
          </Button>
          <Button component={Link} onClick={handleClose} to="/venues/favourites" color="inherit">
            Favourites
          </Button>
          <Button component={Link} onClick={handleClose} to="/profile" color="inherit">
            Profile
          </Button>
          <Button component={Link} onClick={handleClose} to="/help-links" color="inherit">
            Help Links
          </Button>
          <Button onClick={handleLogout} color="inherit">
            Log out
          </Button>
        </>
      }>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        open={open}
        onClose={handleClose}>
        <MenuItem component={Link} onClick={handleClose} to="/">
          Venues
        </MenuItem>
        <MenuItem component={Link} onClick={handleClose} to="/visits">
          Visits
          {upcomingVisits?.length > 0 && (
            <Chip label={upcomingVisits.length} size="small" sx={{ml: 0.5}} variant="outlined" />
          )}
        </MenuItem>
        <MenuItem component={Link} onClick={handleClose} to="/venues/favourites">
          Favourites
        </MenuItem>
        <MenuItem component={Link} onClick={handleClose} to="/profile">
          Profile
        </MenuItem>
        <MenuItem component={Link} onClick={handleClose} to="/help-links">
          Help Links
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </SharedAppBar>
  );
};

export default WebAppHeader;
