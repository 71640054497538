import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {mapVisit} from '../../util';

const useVisitById = (visitId) =>
  useQuery({
    queryKey: [`visits-${visitId}`],
    queryFn: async () =>
      await axios.get(`/api/attends/${visitId}`).then((res) => mapVisit(res.data)),
    staleTime: Infinity,
  });

export default useVisitById;
