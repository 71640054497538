import {useState} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';

import SharedAppBar from '../components/SharedAppBar';
import {canAccessLocalStorage} from '../util';

const UnauthenticatedAppHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {pathname} = useLocation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const login = () => {
    navigate('/login');
  };

  const signUp = () => {
    navigate('/signup');
  };

  // Hide the navbar on auth screens
  return ['/', '/signup', '/login', '/forgot-password'].includes(pathname) ? null : (
    <SharedAppBar
      handleMenu={handleMenu}
      desktopChildren={
        canAccessLocalStorage && (
          <>
            <Button onClick={login}>Log In</Button>
            <Button onClick={signUp} variant="contained" color="primary">
              Sign Up
            </Button>
          </>
        )
      }>
      {canAccessLocalStorage && (
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'top', horizontal: 'left'}}
          keepMounted
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
          open={open}
          onClose={handleClose}>
          <MenuItem onClick={login}>Log In</MenuItem>
          <MenuItem onClick={signUp}>Sign Up</MenuItem>
        </Menu>
      )}
    </SharedAppBar>
  );
};

export default UnauthenticatedAppHeader;
