import {useMutation, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';

const useCancelVisit = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({visitId}) => axios.put(`/api/attends/${visitId}`, {cancelled: 1}),
    onSuccess: (_, {visitId}) => {
      queryClient.invalidateQueries({queryKey: ['upcoming-visits']});
      queryClient.invalidateQueries({queryKey: [`visits-${visitId}`]});
    },
  });
};

export default useCancelVisit;
