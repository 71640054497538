import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {capitalise} from '../util';
import {List, ListItem} from '@mui/material';

const AdminAudit = ({audit}) => {
  const columns = ['event', 'date', 'user', 'old Value', 'new Value'];
  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} aria-label="venue audit">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column} sx={{fontWeight: 600}}>
                {capitalise(column)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {audit.map((row) => (
            <TableRow key={row.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell component="th" scope="row">
                {capitalise(row.event)}
              </TableCell>
              <TableCell>{new Date(row.created_at).toLocaleString()}</TableCell>
              <TableCell>{row.user?.name ?? '-'}</TableCell>
              <TableCell>
                <List dense>
                  {Object.entries(row.old_values).map(([key, value]) => (
                    <ListItem key={key} sx={{px: 0}}>
                      {capitalise(key)}: {String(value)}
                    </ListItem>
                  ))}
                </List>
              </TableCell>
              <TableCell>
                <List dense>
                  {Object.entries(row.new_values).map(([key, value]) => (
                    <ListItem key={key} sx={{px: 0}}>
                      {capitalise(key)}: {String(value)}
                    </ListItem>
                  ))}
                </List>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminAudit;
