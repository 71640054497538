import {List} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import GroupListItem from '../components/GroupListItem';
import {useVenueGroup} from '../context/VenueGroupProvider';
import AdminList from './AdminList';

const AdminVenueGroupList = ({onClick}) => {
  const navigate = useNavigate();
  const {fetchGroups} = useVenueGroup();
  return (
    <AdminList
      fetchItems={fetchGroups}
      title={'Venue Groups'}
      titleId="admin-venue-group-list-title"
      searchPlaceholder={'Search for a venue group'}
      renderItems={(venueGroups) => (
        <List>
          {venueGroups.data.map((venueGroup) => (
            <GroupListItem
              group={venueGroup}
              key={`venue-group-${venueGroup.id}`}
              onClick={
                onClick
                  ? () => onClick(venueGroup)
                  : () => navigate(`/venue-groups/${venueGroup.id}`)
              }
              showOrgName
            />
          ))}
        </List>
      )}
    />
  );
};

export default AdminVenueGroupList;
