import {useEffect, useState} from 'react';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import MyLocationTwoToneIcon from '@mui/icons-material/MyLocationTwoTone';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';

import {useVenue} from './WebAppVenueProvider';
import Loading from '../components/Loading';
import VenueListItem from '../components/VenueListItem';
import SearchInput from '../components/SearchInput';

const VenueList = () => {
  const {fetchVenues, venues, setVenues, hasMore, setHasMore} = useVenue();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(!venues.length);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilteringByLocation, setIsFilteringByLocation] = useState(false);
  const locationFilterLabel = isFilteringByLocation ? 'Show all venues' : 'Show venues near me';
  const [location, setLocation] = useState();

  // Get venues from API when component is mounted
  useEffect(() => {
    refetchDefaultVenues();
  }, []);

  // fetch venues without any search params, and page index of 1
  const refetchDefaultVenues = async () => {
    const newPageIndex = 1;
    await fetchVenuesWithRequestParams(newPageIndex);
  };

  const fetchVenuesWithRequestParams = async (
    requestPageIndex,
    requestSearchTerm,
    requestLocation,
  ) => {
    setIsLoading(true);
    setVenues([]);
    const response = await fetchVenues(requestPageIndex, requestSearchTerm, requestLocation);
    if (response.ok) {
      const responseJson = await response.json();
      setVenues(responseJson.data);
      setHasMore(responseJson.current_page < responseJson.last_page);
    } else {
      alert('Problem retrieving venues.');
    }
    setCurrentPage(requestPageIndex);
    setIsLoading(false);
  };

  const clearSearch = async () => {
    const newSearchTerm = '';
    setSearchTerm(newSearchTerm);
    // if not filtering by location, just refetch default venues
    if (!isFilteringByLocation) {
      refetchDefaultVenues();
    } else {
      // otherwise refetch venues at page 1 with location filter applied
      const newPageIndex = 1;
      fetchVenuesWithRequestParams(newPageIndex, newSearchTerm, location);
    }
  };

  const handleLocationToggle = (_, value) => {
    const newIsFilteringByLocation = !value;
    if (newIsFilteringByLocation) {
      if (!navigator.geolocation) {
        alert('Geolocation is not supported by your browser.');
      } else {
        setIsLoading(true);
        // getCurrentPosition prompts the user for location permissions
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const {latitude, longitude} = position.coords;
            const newLocation = `${latitude},${longitude}`;
            // store location in state so that the user can also search based on proximity
            setLocation(newLocation);
            fetchVenuesWithRequestParams(1, searchTerm, newLocation);
          },
          // if the user declines permissions, or if there's another error
          // show an alert
          (error) => {
            alert(error.message);
          },
        );
      }
    } else if (!newIsFilteringByLocation) {
      setLocation(undefined);
      // if toggling location filter off, and there's no search term
      // reset to default venue list
      if (searchTerm === '') {
        refetchDefaultVenues();
      } else {
        // otherwise, reapply the search term without the location filter
        fetchVenuesWithRequestParams(1, searchTerm);
      }
    }
    setIsFilteringByLocation(newIsFilteringByLocation);
  };

  return (
    <>
      <Box sx={{display: 'flex'}}>
        <SearchInput
          placeholder={'Search for a venue'}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onSearch={async () => {
            // This is a new search, so reset our page index to 1
            const newPageIndex = 1;
            await fetchVenuesWithRequestParams(
              newPageIndex,
              searchTerm,
              isFilteringByLocation ? location : undefined,
            );
          }}
          onClear={clearSearch}
        />
        <Tooltip title={locationFilterLabel} placement="right">
          <span>
            <ToggleButton
              value={isFilteringByLocation}
              selected={isFilteringByLocation}
              onChange={handleLocationToggle}
              disabled={isLoading}
              aria-label={locationFilterLabel}>
              {isLoading ? (
                <MyLocationTwoToneIcon color={isFilteringByLocation ? 'primary' : undefined} />
              ) : (
                <MyLocationIcon color={isFilteringByLocation ? 'primary' : undefined} />
              )}
            </ToggleButton>
          </span>
        </Tooltip>
      </Box>
      {!isLoading && venues.length === 0 ? (
        <Typography style={{marginTop: '20px'}} component="p" variant="body1">
          No results found.
        </Typography>
      ) : (
        <InfiniteScroll
          dataLength={venues.length}
          next={async () => {
            setIsLoading(true);
            const newPageIndex = currentPage + 1;
            const response = await fetchVenues(newPageIndex, searchTerm, location);
            if (response.ok) {
              const responseJson = await response.json();
              setVenues([...venues, ...responseJson.data]);
              setHasMore(responseJson.current_page < responseJson.last_page);
            } else {
              alert('Problem retrieving venues.');
            }
            setCurrentPage(newPageIndex);
            setIsLoading(false);
          }}
          hasMore={hasMore}>
          <List>
            {venues.map((venue) => (
              <VenueListItem venue={venue} key={`venue-${venue.id}`} />
            ))}
          </List>
        </InfiniteScroll>
      )}
      {isLoading && <Loading />}
    </>
  );
};

export default VenueList;
