import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {IconButton, ListItemButton, ListItemSecondaryAction} from '@mui/material';
import {Link} from 'react-router-dom';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';

import {spaceBottomSmall} from '../useSharedStyles';
import {useAuth} from '../context/WebAppAuthProvider';
import {useState} from 'react';
import NotificationSettingsDialog from './NotificationSettingsDialog';

export const UserList = ({users}) => {
  const {cmsType} = useAuth();
  const [userNotificationsEditing, setUserNotificationsEditing] = useState();
  return (
    <>
      {userNotificationsEditing && (
        <NotificationSettingsDialog
          user={userNotificationsEditing}
          onClose={() => setUserNotificationsEditing(undefined)}
        />
      )}
      <List dense sx={spaceBottomSmall}>
        {users.map((user) => (
          <ListItem key={`user-${user.id}`} disablePadding>
            {cmsType === 'admin' ? (
              <ListItemButton component={Link} to={`/users/${user.id}`}>
                <ListItemText primary={user.name} secondary={`${user.email} - ${user.phone}`} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(event) => {
                      event.preventDefault();
                      setUserNotificationsEditing(user);
                    }}>
                    <EditNotificationsIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            ) : (
              <ListItemText primary={user.name} secondary={`${user.email} - ${user.phone}`} />
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
};
