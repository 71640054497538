import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import {useAuth} from '../context/WebAppAuthProvider';
import VenueListItem from '../components/VenueListItem';

const FavouriteVenuesList = () => {
  const {user} = useAuth();
  const {favourite_stores: favouriteVenues} = user;

  return (
    <>
      <Typography style={{marginTop: '20px'}} variant="h2">
        Favourite Venues
      </Typography>
      {favouriteVenues.length === 0 ? (
        <Typography style={{marginTop: '20px'}} component="p" variant="body1">
          {`You don't have any favourite venues yet.`}
        </Typography>
      ) : (
        <List>
          {favouriteVenues.map((venue) => (
            <VenueListItem venue={venue} key={`venue-${venue.id}`} />
          ))}
        </List>
      )}
    </>
  );
};

export default FavouriteVenuesList;
