import {createContext, useContext, useState} from 'react';
import useVisitDateTime from '../hooks/useVisitDateTime';

const NewVisitContext = createContext();
const NewVisitProvider = ({children}) => {
  const {
    newVisitDate,
    minVisitDate,
    setNewVisitDate,
    newVisitTime,
    minVisitTime,
    setNewVisitTime,
    previousDateWasEntered,
    handleDateChange,
  } = useVisitDateTime();
  const [newVisitQuestionResponses, setNewVisitQuestionResponses] = useState([]);
  const requiredQuestionsComplete = (venue) => {
    const requiredQuestions = venue.questions.filter(({is_required}) => is_required);
    const unansweredRequiredQuestions = requiredQuestions.filter(({id, has_attachment}) => {
      const matchedResponse = newVisitQuestionResponses.find(({question_id}) => question_id === id);
      const isMissingText = (matchedResponse?.response_text ?? '') === '';
      const isMissingFile = !matchedResponse?.file_id;
      return isMissingText || (has_attachment && isMissingFile);
    });
    return unansweredRequiredQuestions.length === 0;
  };

  const [newVisitRequirementIds, setNewVisitRequirementIds] = useState([]);
  const [customRequirements, setCustomRequirements] = useState('');

  const resetNewVisitState = () => {
    setCustomRequirements('');
    setNewVisitQuestionResponses([]);
    setNewVisitRequirementIds([]);
  };

  const setQuestionResponse = (questionId, newResponse) => {
    if (newVisitQuestionResponses.find(({question_id}) => question_id === questionId)) {
      setNewVisitQuestionResponses(
        newVisitQuestionResponses.map((response) =>
          response.question_id === questionId ? {...response, ...newResponse} : response,
        ),
      );
    } else {
      setNewVisitQuestionResponses([
        ...newVisitQuestionResponses,
        {
          question_id: questionId,
          ...newResponse,
        },
      ]);
    }
  };

  const value = {
    newVisitDate,
    minVisitDate,
    setNewVisitDate,
    newVisitTime,
    minVisitTime,
    setNewVisitTime,
    handleDateChange,
    previousDateWasEntered,
    newVisitQuestionResponses,
    setQuestionResponse,
    requiredQuestionsComplete,
    newVisitRequirementIds,
    setNewVisitRequirementIds,
    resetNewVisitState,
    customRequirements,
    setCustomRequirements,
  };
  return <NewVisitContext.Provider value={value}>{children}</NewVisitContext.Provider>;
};

const useNewVisit = () => useContext(NewVisitContext);

export {NewVisitProvider, useNewVisit};
