import {useState} from 'react';
import {useParams, Link} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import GoogleMap, {MapWrapper} from '../components/GoogleMap';
import BackLink from '../components/BackLink';
import NewTabIcon from '../components/NewTabIcon';
import {multiline, spaceBottom} from '../useSharedStyles';
import Loading from '../components/Loading';
import SectionHeading from '../components/SectionHeading';
import VenueOpeningHours from '../components/VenueOpeningHours';
import VenueLinks from '../components/VenueLinks';
import VenueRequirements from '../components/VenueRequirements';
import ProfilePicture from '../components/ProfilePicture';
import ScreenActionContainer from '../components/ScreenActionContainer';
import FavouriteVenueCheckbox from '../components/FavouriteVenueCheckbox';
import PageBackgroundHeader from '../components/PageBackgroundHeader';
import PageHeading from '../components/PageHeading';
import {isInIFrame, canAccessLocalStorage} from '../util';
import useVenueById from './Queries/useVenueById';

const ViewVenue = () => {
  const {id} = useParams();
  const {data: venue, isLoading} = useVenueById(id);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  const [selectedTab, setSelectedTab] = useState('access');

  return (
    <>
      <PageBackgroundHeader />
      {!isInIFrame && <BackLink />}
      {venue && (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="baseline" sx={spaceBottom}>
            <div>
              <PageHeading>{venue.name}</PageHeading>
              <Typography variant="subtitle1">{venue.type}</Typography>
            </div>
            <ScreenActionContainer>
              {canAccessLocalStorage ? (
                <Button
                  component={Link}
                  to={'arrange-visit/when'}
                  variant="contained"
                  color="primary">
                  Plan Visit
                </Button>
              ) : (
                // If there's no localStorage access, we can't authenticate to book
                // (probably because we're in an iFrame and 3rd party cookies are disabled)
                // So try opening the booking flow in a new tab
                <Button
                  component={'a'}
                  target="_blank"
                  href={window.location.href + '/arrange-visit/when'}
                  variant="contained"
                  color="primary">
                  Plan Visit
                </Button>
              )}
            </ScreenActionContainer>
          </Box>
          <Tabs
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value)}
            indicatorColor="primary"
            textColor="inherit"
            scrollButtons="auto"
            variant="scrollable"
            aria-label="venue information tabs">
            <Tab label="Venue Access" value="access" />
            <Tab label="General Information" value="general" />
          </Tabs>
          <Card variant="fullscreen">
            <CardContent>
              {selectedTab === 'access' ? (
                <>
                  <SectionHeading>Accessibility</SectionHeading>
                  <VenueRequirements
                    assistanceProvided={venue.ass_provided}
                    requirements={venue.requirements}
                  />
                </>
              ) : selectedTab === 'general' ? (
                <>
                  <Box display="flex" justifyContent={'space-between'} alignItems="flex-start">
                    {venue.logo && (
                      <ProfilePicture src={venue.logo.public_url} alt={`${venue.name} logo`} />
                    )}
                    <FavouriteVenueCheckbox venue={venue} />
                  </Box>
                  <SectionHeading>About this Venue</SectionHeading>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMd ? 'row' : 'column'}
                    alignItems="flex-start">
                    <div style={{flex: 1}}>
                      {venue.website && (
                        <>
                          <Typography variant="h6">Website:</Typography>
                          <Typography
                            target="_blank"
                            component="a"
                            href={venue.website}
                            variant="body1"
                            sx={{...spaceBottom, display: 'block'}}>
                            {venue.website}
                            <NewTabIcon />
                          </Typography>
                        </>
                      )}
                      <Typography variant="h6">General Information</Typography>
                      <Typography variant="body1" sx={{...multiline, ...spaceBottom}}>
                        {venue.general_info}
                      </Typography>
                    </div>
                    <div style={{flex: 1}}>
                      <Typography variant="h6">Email:</Typography>
                      <Typography variant="body1" sx={spaceBottom}>
                        <a href={`mailto:${venue.email}`} target="__blank">
                          {venue.email}
                          <NewTabIcon />
                        </a>
                      </Typography>
                      <Typography variant="h6">Phone Number:</Typography>
                      <Typography variant="body1" sx={spaceBottom}>
                        <a href={`tel:${venue.tel_no}`} target="__blank">
                          {venue.tel_no}
                          <NewTabIcon />
                        </a>
                      </Typography>
                    </div>
                  </Box>
                  <SectionHeading>Address</SectionHeading>
                  <Typography variant="body1">{`${venue.address_1} ${venue.address_2 ?? ''} ${
                    venue.address_3 ?? ''
                  }`}</Typography>
                  <Typography variant="body1" sx={spaceBottom}>
                    {venue.town} {venue.postcode}
                  </Typography>
                  <Box sx={spaceBottom}>
                    {isInIFrame ? (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          window.open(
                            encodeURI(
                              `https://www.google.com/maps/search/?api=1&query=${venue.latitude},${venue.longitude}`,
                            ),
                          );
                        }}>
                        Open in Maps
                      </Button>
                    ) : (
                      <MapWrapper requireCookies>
                        <GoogleMap
                          markerCoordinates={{
                            lat: venue.latitude,
                            lng: venue.longitude,
                          }}
                          style={{height: 200}}
                        />
                      </MapWrapper>
                    )}
                  </Box>
                  <SectionHeading>Opening Hours</SectionHeading>
                  <VenueOpeningHours opening_hours={venue.opening_hours} />
                  <SectionHeading>Helpful Links</SectionHeading>
                  <VenueLinks links={venue.links} />
                </>
              ) : null}
            </CardContent>
          </Card>
        </>
      )}
      {isLoading && <Loading />}
    </>
  );
};

export default ViewVenue;
