import {useEffect} from 'react';

import {getNonEssentialCookieConsent, isInIFrame} from '../util';

const SCRIPT_ID = 'crisp-chat-script';

const CrispChatScript = () => {
  const hasNonEssentialCookieConsent = getNonEssentialCookieConsent() === 'true';
  // https://help.crisp.chat/en/article/how-to-use-crisp-with-reactjs-fe0eyz/
  useEffect(() => {
    if (!isInIFrame && hasNonEssentialCookieConsent && process.env.CRISP_WEBSITE_ID) {
      // Include the Crisp code here, without the <script></script> tags
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = process.env.CRISP_WEBSITE_ID;

      let crispChatScript = document.getElementById(SCRIPT_ID);
      if (!crispChatScript) {
        // load script if doesn't exist
        crispChatScript = document.createElement('script');
        crispChatScript.src = 'https://client.crisp.chat/l.js';
        crispChatScript.async = 1;
        crispChatScript.id = SCRIPT_ID;
        document.getElementsByTagName('head')[0].appendChild(crispChatScript);
      }
    }
  }, []);
  return null;
};

export default CrispChatScript;
