import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Link} from 'react-router-dom';

import WelcomeLogo from '../images/welcome-logo.png';

const SharedAppBar = ({desktopChildren, children, handleMenu}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <AppBar color="inherit">
        <Container maxWidth="lg">
          <Toolbar disableGutters={true}>
            <IconButton component={Link} to="/" edge="start">
              <img style={{width: '120px'}} src={WelcomeLogo} alt="Homepage" />
            </IconButton>
            <div style={{flexGrow: 1}}></div>
            {isMd ? (
              desktopChildren
            ) : children ? (
              <>
                <IconButton
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  edge="end"
                  color="inherit"
                  onClick={handleMenu}>
                  <MenuIcon />
                </IconButton>
                {children}
              </>
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={theme.mixins.toolbar} />
    </>
  );
};

export default SharedAppBar;
