import {Button, List} from '@mui/material';
import {Link} from 'react-router-dom';

import {useAdminVenue} from './AdminVenueProvider';
import VenueListItem from '../components/VenueListItem';
import AdminList from './AdminList';

const AdminVenueList = ({onClick}) => {
  const {fetchVenues} = useAdminVenue();
  return (
    <AdminList
      fetchItems={fetchVenues}
      title={'Venues'}
      titleId="admin-venue-list-title"
      searchPlaceholder={'Search for a venue'}
      renderItems={(venues) => (
        <List>
          {venues.data.map((venue) => (
            <VenueListItem venue={venue} key={`venue-${venue.id}`} onClick={onClick} />
          ))}
        </List>
      )}
      headerActions={
        <Button variant="contained" component={Link} to="/venues/create">
          + Add New Venue
        </Button>
      }
    />
  );
};

export default AdminVenueList;
