import {useState} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';

import useManualArrive from './Queries/useManualArrive';

const ManualArrive = ({venue, visitId}) => {
  const [beaconId, setbeaconId] = useState();
  const manualArriveMutation = useManualArrive();

  const handleManualArrive = async () => {
    manualArriveMutation.mutate(
      {visitId, beaconId},
      {
        onSuccess: () => alert("We've let the venue know you have arrived."),
        onError: (error) => {
          console.error('manual arrive error', error);
          alert(
            'There was a problem letting the venue know you have arrived, please try again or use the contact information provided.',
          );
        },
      },
    );
  };
  const handleChange = (event) => {
    setbeaconId(parseInt(event.target.value));
  };

  return (
    <div style={{marginTop: '20px'}}>
      <FormControl component="fieldset">
        {venue.beacons.length > 0 && (
          <>
            <FormLabel component="legend">Which entrance have you arrived at?</FormLabel>
            <RadioGroup
              aria-label="entrance"
              name="entrance1"
              value={beaconId ?? ''}
              onChange={handleChange}>
              {venue.beacons.map((entrance) => (
                <FormControlLabel
                  key={`entrance-${entrance.id}`}
                  value={entrance.id}
                  control={<Radio />}
                  label={entrance.name}
                />
              ))}
            </RadioGroup>
          </>
        )}
      </FormControl>
      <div>
        <Button variant="contained" color="primary" onClick={handleManualArrive}>
          Confirm Arrival
        </Button>
      </div>
    </div>
  );
};

export default ManualArrive;
