import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {mapVisit} from '../../util';

const usePastVisits = () =>
  useQuery({
    queryKey: ['past-visits'],
    queryFn: async () =>
      await axios.get('/api/attends?date=past').then((res) => res.data.map(mapVisit)),
    staleTime: Infinity,
  });

export default usePastVisits;
