import {useEffect, useState} from 'react';

import {Route, useLocation, useNavigate} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import SentryRoutes from '../SentryRoutes';
import WebAppSignUp from './WebAppSignUp';
import WebAppLogin from '../screens/WebAppLogin';
import '../App.css';
import {useAuth} from '../context/WebAppAuthProvider';
import {VenueProvider} from './WebAppVenueProvider';
import ViewVenue from './ViewVenue';
import VenueList from './VenueList';
import FavouriteVenuesList from './FavouriteVenuesList';
import WebAppHeader from './WebAppHeader';
import VisitDateTime from './VisitDateTime';
import VisitQuestions from './VisitQuestions';
import VisitRequirements from './VisitRequirements';
import VisitSuccess from './VisitSuccess';
import ViewVisits from './ViewVisits';
import ViewVisitDetails from './ViewVisitDetails';
import StoreRedirectUrlForAuth from '../screens/StoreRedirectUrlForAuth';
import Profile from '../screens/Profile';
import ProfileDisabilities from './ProfileDisabilities';
import ProfileRequirements from './ProfileRequirements';
import ProfileChangePicture from '../screens/ProfileChangePicture';
import HelpLinks from './HelpLinks';
import ForgotPassword from '../screens/ForgotPassword';
import Loading from '../components/Loading';
import CrispChatScript from '../components/CrispChatScript';
import UnauthenticatedAppHeader from './UnauthenticatedAppHeader';
import OrgVenueList from '../OrgWebApp/OrgVenueList';
import OrgGroupDetails from '../OrgWebApp/OrgGroupDetails';
import OrgProvider from '../context/OrgProvider';
import VenueGroupProvider from '../context/VenueGroupProvider';
import VisitFeedbackForm from './VisitFeedbackForm';
import FeedbackProvider from '../context/FeedbackProvider';
import VenueListProvider from '../context/VenueListProvider';
import ViewVenueList from './ViewVenueList';
import CookieConsentBanner from '../components/CookieConsentBanner';
import {NewVisitProvider} from './NewVisitProvider';
import useUpcomingVisits from './Queries/useUpcomingVisits';
import PostSignupVisitQuestionsDialog from './components/PostSignupVisitQuestionsDialog';
import AppContainer from '../components/AppContainer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const UnauthenticatedApp = () => (
  <SentryRoutes>
    <Route path="/" element={<WebAppLogin />} />
    <Route path="/login" element={<WebAppLogin />} />
    <Route path="/signup" element={<WebAppSignUp />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/venues/:id" element={<ViewVenue />} />
    <Route path="/organisations/:id" element={<OrgVenueList />} />
    <Route path="/groups/:id" element={<OrgGroupDetails />} />
    <Route path="/venue-lists/:id" element={<ViewVenueList />} />
    <Route
      path="*"
      element={
        <>
          <StoreRedirectUrlForAuth />
          <WebAppLogin />
        </>
      }
    />
  </SentryRoutes>
);

const AuthenticatedApp = () => {
  const {redirectUrlForAuth} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectUrlForAuth) {
      navigate(redirectUrlForAuth);
    }
  }, [redirectUrlForAuth]);

  // After signup, check for existing visits with required questions the user has not answered
  const {data: upcomingVisits} = useUpcomingVisits();
  const visitsWithUnansweredQuestions =
    upcomingVisits?.filter((visit) => visit.hasUnansweredVisitQuestions) ?? [];
  const {state} = useLocation();
  const [showPostSignupVisitQuestionsDialog, setShowPostSignupVisitQuestionsDialog] =
    useState(false);
  useEffect(() => {
    setShowPostSignupVisitQuestionsDialog(
      state?.didRegister && visitsWithUnansweredQuestions.length > 0,
    );
  }, [state?.didRegister, visitsWithUnansweredQuestions]);

  return (
    <>
      {showPostSignupVisitQuestionsDialog && (
        <PostSignupVisitQuestionsDialog
          onClose={() => setShowPostSignupVisitQuestionsDialog(false)}
        />
      )}
      <SentryRoutes>
        <Route path="/" element={<VenueList />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/disabilities" element={<ProfileDisabilities />} />
        <Route path="/profile/requirements" element={<ProfileRequirements />} />
        <Route path="/profile/picture" element={<ProfileChangePicture />} />
        <Route path="/venues" element={<VenueList />} />
        <Route path="/venues/favourites" element={<FavouriteVenuesList />} />
        <Route path="/venues/:id" element={<ViewVenue />} />
        <Route path="/venues/:id/arrange-visit/when" element={<VisitDateTime />} />
        <Route path="/venues/:id/arrange-visit/questions" element={<VisitQuestions />} />
        <Route path="/venues/:id/arrange-visit/requirements" element={<VisitRequirements />} />
        <Route path="/venues/:id/arrange-visit/success" element={<VisitSuccess />} />
        <Route path="/organisations/:id" element={<OrgVenueList />} />
        <Route path="/groups/:id" element={<OrgGroupDetails />} />
        <Route path="/venue-lists/:id" element={<ViewVenueList />} />
        <Route path="/visits" element={<ViewVisits />} />
        <Route path="/visits/:id" element={<ViewVisitDetails />} />
        <Route path="/feedback/:id" element={<VisitFeedbackForm />} />
        <Route path="/help-links" element={<HelpLinks />} />
        <Route path="*" element={<VenueList />} />
      </SentryRoutes>
    </>
  );
};

const WebApp = () => {
  const {isAuthenticated, isCheckingAuth} = useAuth();
  return (
    <QueryClientProvider client={queryClient}>
      <OrgProvider>
        <VenueGroupProvider>
          <VenueListProvider>
            {isCheckingAuth ? (
              <Loading />
            ) : isAuthenticated ? (
              <VenueProvider>
                <NewVisitProvider>
                  <FeedbackProvider>
                    <WebAppHeader />
                    <AppContainer>
                      <AuthenticatedApp />
                    </AppContainer>
                  </FeedbackProvider>
                </NewVisitProvider>
              </VenueProvider>
            ) : (
              <VenueProvider>
                <UnauthenticatedAppHeader />
                <AppContainer>
                  <UnauthenticatedApp />
                </AppContainer>
              </VenueProvider>
            )}
            <CookieConsentBanner />
          </VenueListProvider>
          <CrispChatScript />
        </VenueGroupProvider>
      </OrgProvider>
    </QueryClientProvider>
  );
};

export default WebApp;
