import {useMutation, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';

const useUpdateVisitQuestionResponses = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({visitId, question_responses}) =>
      axios.put(`/api/attends/${visitId}`, {question_responses}),
    onSuccess: (_, {visitId}) => {
      queryClient.invalidateQueries({queryKey: ['upcoming-visits']});
      queryClient.invalidateQueries({queryKey: [`visits-${visitId}`]});
    },
  });
};

export default useUpdateVisitQuestionResponses;
