import {useEffect} from 'react';

import Loading from '../components/Loading';
import {useAuth} from '../context/WebAppAuthProvider';

const AppointeddWidget = ({appointeddUrl, handleBookingEvent}) => {
  const {appointeddPrefillFields} = useAuth();

  const receiveAppointeddMessage = (event) => {
    const {data, origin} = event;
    if (!origin.includes('appointedd')) {
      // Only pay attention to appointedd's events
      return;
    }
    // On successfull booking, the iframe sends a message with a data value like this:
    // "appointeddBookingConfirmed.{[JSON_ENCODED_VISIT_DATA]}"
    if (data.includes('appointeddBookingConfirmed')) {
      let booking = data.substring(data.indexOf('.') + 1);
      try {
        booking = JSON.parse(booking);
        const bookingDateTime = new Date(Date.parse(booking.start_time));
        const bookingDate = bookingDateTime.toISOString().split('T')[0];
        const bookingTime = bookingDateTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
        handleBookingEvent(bookingDate, bookingTime);
      } catch (err) {
        console.warn('Failed getting JSON from event ' + data, err);
        console.warn(booking);
      }
    }
  };

  useEffect(() => {
    // (NOTE: only the non-enterprise booking widget sends events through window. The enterprise one uses a different approach)
    // (see https://support.appointedd.com/en/articles/3778756-listening-for-events-in-the-enterprise-booking-widget)
    window.addEventListener('message', receiveAppointeddMessage, false);
    return () => {
      window.removeEventListener('message', receiveAppointeddMessage);
    };
  }, []);

  // Create an appointedd widget url with pre-filled fields
  const preFilledAppointeddUrl =
    appointeddUrl && appointeddPrefillFields
      ? `${appointeddUrl}?customerPrefill[firstname]=${appointeddPrefillFields.firstName}&customerPrefill[lastname]=${appointeddPrefillFields.lastName}&customerPrefill[email]=${appointeddPrefillFields.email}&customerPrefill[mobile]=${appointeddPrefillFields.mobile}`
      : null;

  return preFilledAppointeddUrl ? (
    <iframe
      width="100%"
      height="550px"
      src={preFilledAppointeddUrl}
      title="Appointedd Booking Widget"></iframe>
  ) : (
    <Loading />
  );
};

export default AppointeddWidget;
