import {createContext, useContext} from 'react';
import {useAuth} from '../context/WebAppAuthProvider';

const AdminContext = createContext({});

const AdminVenueProvider = ({children}) => {
  const {AUTHENTICATED_HEADERS, handleUnauthorized} = useAuth();

  const fetchVenues = async (page = 1, searchTerm = '') => {
    const query = new URLSearchParams({
      page,
      // we can send searchString with every request because the API
      // will treat '' as empty
      search_string: searchTerm,
    }).toString();
    const response = await fetch(`/api/cms/stores?${query}`, {
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't retrieve venues");
        console.error('Failed to fetch venues', response);
      }
    }
  };

  const deleteVenue = async (venueId) => {
    const response = await fetch(`/api/cms/stores/${venueId}`, {
      method: 'DELETE',
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      return true;
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't delete venue");
        console.error('Failed to delete venue', response);
      }
    }
  };

  return (
    <AdminContext.Provider value={{fetchVenues, deleteVenue}}>{children}</AdminContext.Provider>
  );
};

export const useAdminVenue = () => useContext(AdminContext);

export default AdminVenueProvider;
