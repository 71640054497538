import {useMutation, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';

const useSubmitVisit = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => axios.post('/api/attends', data),
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['upcoming-visits']}),
  });
};

export default useSubmitVisit;
