import {Link} from 'react-router-dom';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

const PostSignupVisitQuestionsDialog = ({onClose}) => (
  <Dialog open onClose={onClose} maxWidth="md">
    <DialogTitle textAlign="center">Thanks for signing up!</DialogTitle>
    <DialogContent>
      <Typography paragraph>It looks like you already have some visits booked.</Typography>
      <Typography>Some of these visits have additional questions to answer.</Typography>
    </DialogContent>
    <DialogActions sx={{mb: 2}}>
      <Button component={Link} state={{didRegister: undefined}} variant="outlined" sx={{mr: 1}}>
        Not now
      </Button>
      <Button component={Link} to="/visits" variant="contained">
        Show me my visits
      </Button>
    </DialogActions>
  </Dialog>
);
export default PostSignupVisitQuestionsDialog;
