import {createContext, useContext} from 'react';
import {useAuth} from './WebAppAuthProvider';
import uploadFile from '../Onboarding/fileUploadService';

const OrgContext = createContext({});

const OrgProvider = ({cms = false, children}) => {
  const {AUTHENTICATED_HEADERS, handleUnauthorized, token} = useAuth();

  const fetchOrganisations = async (page = 1, searchTerm = '') => {
    const query = new URLSearchParams({
      page,
      // we can send searchString with every request because the API
      // will treat '' as empty
      search_string: searchTerm,
    }).toString();
    const response = await fetch(`api/cms/organisations?${query}`, {
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't retrieve organisations");
        console.error('Failed to fetch organisations', response);
      }
    }
  };

  const fetchOrg = async (id) => {
    const response = await fetch(
      cms ? `/api/cms/organisations/${id}` : `/api/organisations/${id}`,
      {
        headers: AUTHENTICATED_HEADERS,
      },
    );
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't retrieve organisation");
        console.error('Failed to fetch organisation', response);
      }
    }
  };

  const createOrganisation = async (organisation) => {
    const response = await fetch(`/api/cms/organisations`, {
      method: 'POST',
      headers: AUTHENTICATED_HEADERS,
      body: JSON.stringify(organisation),
    });
    if (response.ok) {
      const responseJson = await response.json();
      const organisation = await fetchOrg(responseJson.id);
      return organisation;
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't create organisation - please refresh and try again");
        console.error('Failed to create organisation', errors);
      }
    }
  };

  const updateOrganisation = async (id, organisation) => {
    const response = await fetch(`/api/cms/organisations/${id}`, {
      method: 'PUT',
      headers: AUTHENTICATED_HEADERS,
      body: JSON.stringify(organisation),
    });
    if (response.ok) {
      const organisation = await fetchOrg(id);
      return organisation;
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't update organisation - please refresh and try again");
        console.error('Failed to update organisation', errors);
      }
    }
  };

  const uploadOrganisationLogo = async (logo) => {
    return await uploadFile(token, logo);
  };

  const testSpektrixApiCredentials = async (clientname, key, username) => {
    const response = await fetch('/api/cms/organisations/test-spektrix-credentials', {
      method: 'POST',
      headers: AUTHENTICATED_HEADERS,
      body: JSON.stringify({
        clientname,
        key,
        username,
      }),
    });
    const body = await response.text();
    return body === 'true';
  };

  const fetchSpektrixVenues = async (orgID) => {
    const response = await fetch(`/api/cms/organisations/${orgID}/spektrix-venues`, {
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't retrieve organisation");
        console.error('Failed to fetch organisation', response);
      }
    }
  };

  return (
    <OrgContext.Provider
      value={{
        fetchOrganisations,
        fetchOrg,
        uploadOrganisationLogo,
        updateOrganisation,
        createOrganisation,
        testSpektrixApiCredentials,
        fetchSpektrixVenues,
      }}>
      {children}
    </OrgContext.Provider>
  );
};

export const useOrg = () => useContext(OrgContext);

export default OrgProvider;
