import {Link} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

import {arrivalToString} from '../util';
import VisitStatus from './VisitStatus';

const VisitRow = ({visit}) => {
  const {store: venue} = visit;
  return (
    <ListItem divider disablePadding>
      <ListItemButton component={Link} to={`/visits/${visit.id}`} alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={`${venue.name} logo`} src={venue.logo.public_url} />
        </ListItemAvatar>
        <ListItemText
          primary={venue.name}
          secondary={
            <>
              <Typography component="span" variant="body1">
                {`${venue.address_1}, ${venue.address_2}, ${venue.town}`}
              </Typography>
              <br />
              <Typography component="span" variant="body1">
                {arrivalToString(visit.arrival, visit.arrival_end)}
              </Typography>
            </>
          }
        />
        <ListItemSecondaryAction>
          <VisitStatus visitStatus={visit.status} />
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
};
export default VisitRow;
