import {createContext, useContext} from 'react';
import {useAuth} from './WebAppAuthProvider';

const FeedbackContext = createContext();

const FeedbackProvider = ({children}) => {
  const {AUTHENTICATED_HEADERS, handleUnauthorized} = useAuth();

  const fetchQuestions = async () => {
    const questionsResponse = await fetch('/api/feedback-questions');
    return await questionsResponse.json();
  };

  const updateQuestions = async (questions) => {
    const response = await fetch('/api/cms/feedback-questions', {
      method: 'PUT',
      headers: AUTHENTICATED_HEADERS,
      body: JSON.stringify({questions}),
    });
    if (response.ok) {
      return await response.json();
    } else {
      if (!handleUnauthorized(response)) {
        alert("Couldn't update questions");
        console.error('Failed to update questions', response);
      }
    }
  };

  const fetchFeedback = async (page = 1) => {
    const query = new URLSearchParams({page}).toString();
    const response = await fetch(`/api/cms/feedback?${query}`, {headers: AUTHENTICATED_HEADERS});
    if (response.ok) {
      return await response.json();
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't fetch feedback - please refresh and try again");
        console.error('Failed to fetch feedback', errors);
      }
    }
  };

  const fetchFeedbackForVisit = async (attendId) => {
    const response = await fetch(`/api/cms/feedback/${attendId}`, {headers: AUTHENTICATED_HEADERS});
    if (response.ok) {
      return await response.json();
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't fetch feedback - please refresh and try again");
        console.error('Failed to fetch feedback', errors);
      }
    }
  };

  const submitFeedback = async (responses, attend_id) => {
    const response = await fetch('/api/feedback', {
      method: 'POST',
      headers: AUTHENTICATED_HEADERS,
      body: JSON.stringify({
        responses: responses.map((response) => ({
          attend_id,
          feedback_question_id: response.question.id,
          rating: response.rating,
          text_response: response.text_response,
        })),
      }),
    });
    if (response.ok) {
      return response;
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't submit feedback - please refresh and try again");
        console.error('Failed to submit feedback', errors);
      }
    }
  };

  return (
    <FeedbackContext.Provider
      value={{
        fetchQuestions,
        updateQuestions,
        fetchFeedback,
        submitFeedback,
        fetchFeedbackForVisit,
      }}>
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = () => useContext(FeedbackContext);

export default FeedbackProvider;
