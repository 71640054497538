import {useEffect} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';

import useVenueById from './Queries/useVenueById';

const useVisitRedirectIfNoVenue = () => {
  const {id} = useParams();
  const {data: venue} = useVenueById(id);
  const location = useLocation();
  const navigate = useNavigate();
  const guardedRoutes = [
    `/venues/${id}/arrange-visit/requirements`,
    `/venues/${id}/arrange-visit/questions`,
    `/venues/${id}/arrange-visit/success`,
  ];
  const redirectTo = `/venues/${id}/arrange-visit/when`;

  useEffect(() => {
    if (!venue && guardedRoutes.includes(location.pathname)) {
      navigate(redirectTo);
    }
  }, []);
};
export default useVisitRedirectIfNoVenue;
