import Box from '@mui/material/Box';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Zoom from '@mui/material/Zoom';
import useTheme from '@mui/material/styles/useTheme';

const ScreenActionContainer = ({children}) => {
  const theme = useTheme();
  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 110,
  });
  // If we are running in Cypress, we need to prevent the
  // buttons being repeatedly added/removed from the DOM
  return !isScrolled || window.Cypress ? (
    children
  ) : (
    <Zoom in={isScrolled}>
      <Box
        sx={{
          position: 'fixed',
          top: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(2)})`,
          right: theme.spacing(2),
          zIndex: 10,
        }}>
        {children}
      </Box>
    </Zoom>
  );
};

export default ScreenActionContainer;
