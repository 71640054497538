import {Dialog, DialogContent} from '@mui/material';
import AdminOrgList from '../AdminWebApp/AdminOrgList';

const AdminOrgSelectDialog = ({setIsOpen, setOrganisation}) => (
  <Dialog open onClose={() => setIsOpen(false)} fullWidth aria-labelledby="admin-org-list-title">
    <DialogContent>
      <AdminOrgList
        onClick={(newOrganisation) => {
          setOrganisation(newOrganisation);
          setIsOpen(false);
        }}
      />
    </DialogContent>
  </Dialog>
);

export default AdminOrgSelectDialog;
