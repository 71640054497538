import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {mapVisit} from '../../util';

const useUpcomingVisits = () =>
  useQuery({
    queryKey: ['upcoming-visits'],
    queryFn: async () => await axios.get('/api/attends').then((res) => res.data.map(mapVisit)),
    staleTime: Infinity,
  });

export default useUpcomingVisits;
