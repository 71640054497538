import {useTheme} from '@mui/material/styles';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';

const OrgListItem = ({organisation, onClick}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <ListItem disablePadding>
      <ListItemButton
        alignItems="flex-start"
        onClick={
          onClick
            ? () => onClick(organisation)
            : () => navigate(`/organisations/${organisation.id}`)
        }
        divider>
        {isMd && (
          <ListItemAvatar>
            {/* Org profile pictures can be null, so use optional chaining */}
            <Avatar alt={`${organisation.name} logo`} src={organisation.logo?.public_url} />
          </ListItemAvatar>
        )}
        <ListItemText primary={organisation.name} />
      </ListItemButton>
    </ListItem>
  );
};

export default OrgListItem;
