import {Button, List, ListItem, ListItemText} from '@mui/material';
import {Box} from '@mui/system';
import {useState} from 'react';
import OrgListItem from '../AdminWebApp/OrgListItem';
import {spaceBottom} from '../useSharedStyles';
import AdminOrgSelectDialog from './AdminOrgSelectDialog';
import SectionHeading from './SectionHeading';

const AssignedOrganisation = ({organisation, setOrganisation, isEditing}) => {
  const [editIsOpen, setEditIsOpen] = useState(false);
  return (
    <Box sx={spaceBottom}>
      <SectionHeading
        button={
          isEditing && (
            <Button variant="outlined" onClick={() => setEditIsOpen(true)}>
              Change Organisation
            </Button>
          )
        }>
        Assigned Organisation
      </SectionHeading>

      {editIsOpen && (
        <AdminOrgSelectDialog setIsOpen={setEditIsOpen} setOrganisation={setOrganisation} />
      )}
      <List>
        {organisation ? (
          <OrgListItem organisation={organisation} onClick={isEditing ? () => {} : undefined} />
        ) : (
          <ListItem>
            <ListItemText primary="No Organisation selected" />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default AssignedOrganisation;
