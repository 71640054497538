import {createContext, useContext, useState, useEffect} from 'react';
import {useAuth} from './WebAppAuthProvider';

const RolesContext = createContext();

const RolesProvider = ({children}) => {
  const {AUTHENTICATED_HEADERS} = useAuth();
  const [allRoles, setAllRoles] = useState([]);

  const fetchRoles = async () => {
    const rolesResponse = await fetch('/api/cms/roles', {
      headers: AUTHENTICATED_HEADERS,
    });

    if (rolesResponse.ok) {
      const responseJson = await rolesResponse.json();
      setAllRoles(responseJson);
    } else {
      alert("Couldn't retrieve roles.");
      console.error('Failed to fetch roles', rolesResponse);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const value = {
    allRoles,
    fetchRoles,
  };
  return <RolesContext.Provider value={value}>{children}</RolesContext.Provider>;
};

export const useRoles = () => useContext(RolesContext);

export default RolesProvider;
