import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

const statusBackgroundColors = {
  'status-warning': '#FA9F42',
  'status-okay': '#095D42',
  'status-reject': '#999999',
};
const statusTextColors = {
  'status-warning': 'black',
  'status-okay': 'white',
  'status-reject': 'black',
};

const VisitStatus = ({visitStatus}) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        width: 'fit-content',
        py: 0.5,
        px: 1,
        borderRadius: 0.25,
        backgroundColor: statusBackgroundColors[visitStatus.class],
      }}>
      <Tooltip title={visitStatus.tooltip} aria-label={visitStatus.tooltip}>
        <Typography
          variant="body1"
          sx={{
            color: statusTextColors[visitStatus.class],
          }}>
          {visitStatus.status} {visitStatus.delay > 0 ? `${visitStatus.delay}m` : ''}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default VisitStatus;
