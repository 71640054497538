import {List, ListItem, ListItemButton, ListItemText, Typography} from '@mui/material';

import Loading from '../components/Loading';
import useAllRequirements from '../Queries/useAllRequirements';
import ListCheckbox from './ListCheckbox';
import {useAuth} from '../context/WebAppAuthProvider';

const RequirementsList = ({selectedRequirements, setSelectedRequirements}) => {
  const {useCMSEndpoints} = useAuth();
  const {isLoading, data: allRequirements, isError} = useAllRequirements(useCMSEndpoints);
  const selectedRequirementIDs = selectedRequirements.map(({id}) => id);

  const handleToggleRequirement = (toggleRequirement) => {
    if (selectedRequirementIDs.includes(toggleRequirement.id)) {
      setSelectedRequirements(
        selectedRequirements.filter((requirement) => requirement.id !== toggleRequirement.id),
      );
    } else {
      setSelectedRequirements([...selectedRequirements, toggleRequirement]);
    }
  };

  return isLoading ? (
    <Loading padding={false} />
  ) : isError ? (
    <Typography>Error loading accessibility features - please reload and try again</Typography>
  ) : allRequirements ? (
    <List data-testid="requirements-list">
      {allRequirements.map((requirement) => {
        const {id, name} = requirement;
        const labelId = `requirement-${id}`;
        return (
          <ListItem key={labelId} disablePadding>
            <ListItemButton onClick={() => handleToggleRequirement(requirement)}>
              <ListItemText id={labelId} primary={name} />
              <ListCheckbox selected={selectedRequirementIDs.includes(id)} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  ) : null;
};

export default RequirementsList;
