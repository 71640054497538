import {
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';

const GroupListItem = ({group, onClick, showOrgName = false}) => (
  <ListItem disablePadding>
    <ListItemButton
      onClick={onClick}
      divider
      sx={{flexDirection: 'column', alignItems: 'flex-start'}}>
      <ListItemText
        primary={group.name}
        secondary={`${group.venues.length} ${group.venues.length === 1 ? 'venue' : 'venues'}`}
      />
      {showOrgName && group?.organisation && (
        <ListItemSecondaryAction>
          <Typography variant="body1">({group.organisation?.name})</Typography>
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  </ListItem>
);
export default GroupListItem;
