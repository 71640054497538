import {Fragment} from 'react';

import Button from '@mui/material/Button';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import {capitalise} from '../util';
import {days} from '../constants';

const simplifyTime = (time) => {
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}`;
};

const VenueOpeningHours = ({opening_hours, isEditing = false, setOpeningHours}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));

  // group opening hours by day
  const hoursByDay = {};
  days.forEach((day) => (hoursByDay[day] = opening_hours.filter((hours) => hours.day === day)));

  const isSameHour = (hour, otherHour) => {
    return (
      hour.day === otherHour.day &&
      simplifyTime(hour.open) === simplifyTime(otherHour.open) &&
      simplifyTime(hour.close) === simplifyTime(otherHour.close) &&
      hour.id === otherHour.id
    );
  };
  const updateHourOpen = (hour, open) => {
    setOpeningHours(
      opening_hours.map((eachHour) =>
        isSameHour(hour, eachHour) ? {...eachHour, open} : eachHour,
      ),
    );
  };
  const updateHourClose = (hour, close) => {
    setOpeningHours(
      opening_hours.map((eachHour) =>
        isSameHour(hour, eachHour) ? {...eachHour, close} : eachHour,
      ),
    );
  };

  const removeHour = (hour) => {
    setOpeningHours(opening_hours.filter((opening_hour) => opening_hour !== hour));
  };

  const addHour = (day) => {
    setOpeningHours([
      ...opening_hours,
      {
        day,
        open: '09:00',
        close: '17:00',
      },
    ]);
  };

  return (
    <>
      {Object.entries(hoursByDay)
        .filter(([, hours]) => isEditing || hours.length > 0)
        .map(([day, hours]) => (
          <Fragment key={day}>
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent={isMd ? 'flex-start' : 'space-between'}>
              <Typography variant="subtitle1" component="h3" sx={{mb: 0}}>
                {capitalise(day)}
              </Typography>
              {isEditing && (
                <Button
                  name={`add-${day}`}
                  variant="outlined"
                  color="primary"
                  onClick={() => addHour(day)}
                  style={{marginLeft: theme.spacing(1)}}
                  size={isMd ? 'medium' : 'small'}>
                  Add
                </Button>
              )}
            </Box>
            <List dense>
              {hours.map((hour, index) => {
                const removeButton = (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeHour(hour)}
                    color="primary">
                    <DeleteIcon />
                  </IconButton>
                );
                return (
                  <ListItem key={`${hour.day}-${index}`} data-testid={`${hour.day}-${index}`} dense>
                    {isEditing ? (
                      <>
                        <TextField
                          inputProps={{'aria-label': 'Start time'}}
                          type="time"
                          required
                          value={hour.open}
                          onChange={(event) => updateHourOpen(hour, event.target.value)}
                        />
                        <HorizontalRuleIcon />
                        <TextField
                          inputProps={{'aria-label': 'End time'}}
                          type="time"
                          required
                          value={hour.close}
                          onChange={(event) => updateHourClose(hour, event.target.value)}
                        />
                        {isMd ? (
                          removeButton
                        ) : (
                          <ListItemSecondaryAction>{removeButton}</ListItemSecondaryAction>
                        )}
                      </>
                    ) : (
                      <ListItemText
                        primary={`${simplifyTime(hour.open)} - ${simplifyTime(hour.close)}`}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Fragment>
        ))}
    </>
  );
};

export default VenueOpeningHours;
