import {useState, useEffect} from 'react';

import Loading from '../components/Loading';
import {useAuth} from '../context/WebAppAuthProvider';

const SCRIPT_ID = 'appointedd-enterprise-widget-script';
const DIV_ID = 'appointedd-enterprise-widget';

const AppointeddEnterpriseWidget = ({appointeddUrl, handleBookingEvent}) => {
  const [isLoading, setIsLoading] = useState(true);
  const {appointeddPrefillFields} = useAuth();
  // Find the script URL based on the appointedd URL: https://support.appointedd.com/en/articles/3778703-rendering-the-enterprise-booking-widget
  const scriptURL = `${appointeddUrl}/widget.js`;

  useEffect(() => {
    let script = document.getElementById(SCRIPT_ID);
    if (!script) {
      // load script if doesn't already exist
      script = document.createElement('script');
      script.src = scriptURL;
      script.id = SCRIPT_ID;
      window.document.body.appendChild(script);
    }
    script.addEventListener('load', () => {
      // Pre-fill the appointedd enterprise widget with the user's details
      // (see https://support.appointedd.com/en/articles/3778714-rendering-the-enterprise-booking-widget-with-a-configuration)
      appointeddWidget.render(DIV_ID, {
        preset: {
          customer: {
            firstname: appointeddPrefillFields.firstName,
            lastname: appointeddPrefillFields.lastName,
            email: appointeddPrefillFields.email,
            mobile: appointeddPrefillFields.mobile,
          },
        },
      });
      appointeddWidget.on('booking_created', function ({booking}) {
        const bookingDateTime = new Date(Date.parse(booking.start_time));
        const bookingDate = bookingDateTime.toISOString().split('T')[0];
        const bookingTime = bookingDateTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
        handleBookingEvent(bookingDate, bookingTime);
      });
      appointeddWidget.on('ready', function () {
        setIsLoading(false);
      });
    });

    return () => {
      script.remove();
    };
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <div id={DIV_ID} style={isLoading ? {display: 'none'} : {}} />
    </>
  );
};

export default AppointeddEnterpriseWidget;
