import {useParams, useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import {useNewVisit} from './NewVisitProvider';
import {useAuth} from '../context/WebAppAuthProvider';
import useVisitRedirectIfNoVenue from './useVisitRedirectIfNoVenue';
import BackLink from '../components/BackLink';
import useVenueById from './Queries/useVenueById';
import Loading from '../components/Loading';
import PageHeading from '../components/PageHeading';
import useSubmitVisit from './Queries/useSubmitVisit';

const OTHER_REQUIREMENTS_LENGTH = 255;

const VisitRequirements = () => {
  const {user} = useAuth();
  const {id} = useParams();
  const {data: venue, isLoading} = useVenueById(id);
  const navigate = useNavigate();
  const submitVisitMutation = useSubmitVisit();

  const {
    newVisitDate,
    newVisitTime,
    newVisitRequirementIds,
    newVisitQuestionResponses,
    setNewVisitRequirementIds,
    customRequirements,
    setCustomRequirements,
  } = useNewVisit();

  const matchingRequirements =
    user?.requirements?.filter((userRequirement) => {
      return venue?.requirements?.some((venueRequirement) => {
        return userRequirement.id === venueRequirement.id;
      });
    }) ?? [];

  const otherRequirements =
    venue?.requirements?.filter(
      (venueRequirement) =>
        !user?.requirements?.some((userRequirement) => userRequirement.id === venueRequirement.id),
    ) ?? [];

  const handleToggleRequirementId = (requirementId) => {
    const currentIndex = newVisitRequirementIds.indexOf(requirementId);
    const requirementIds = JSON.parse(JSON.stringify([...newVisitRequirementIds]));
    if (currentIndex === -1) {
      requirementIds.push(requirementId);
    } else {
      requirementIds.splice(currentIndex, 1);
    }
    setNewVisitRequirementIds(requirementIds);
  };

  const handleSubmitVisit = () =>
    submitVisitMutation.mutate(
      {
        store_id: venue.id,
        arrival: `${newVisitDate} ${newVisitTime}:00`,
        requirements: newVisitRequirementIds,
        question_responses: newVisitQuestionResponses,
        ...(customRequirements !== '' && {other_requirements: customRequirements}),
      },
      {
        onSuccess: () => {
          navigate(`/venues/${venue.id}/arrange-visit/success`);
        },
        onError: (error) => {
          console.error('submit visit error', error);
          alert('There was a problem arranging your visit.');
        },
      },
    );

  useVisitRedirectIfNoVenue();

  const prevScreen = venue?.questions.length > 0 ? 'questions' : 'when';
  const backLinkTo = `/venues/${id}/arrange-visit/${prevScreen}`;

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <Typography variant="h3" sx={{mb: 4}}>
        Your visit to {venue.name}
      </Typography>
      <PageHeading>What do you want to do on this visit?</PageHeading>
      <Typography variant="h3" component="label" htmlFor="visit-custom-requirements">
        Custom requirements:
      </Typography>
      <TextField
        id="visit-custom-requirements"
        type="text"
        fullWidth
        multiline
        minRows={2}
        maxRows={5}
        value={customRequirements}
        onChange={(event) => {
          setCustomRequirements(event.target.value.slice(0, OTHER_REQUIREMENTS_LENGTH));
        }}
        helperText={`${customRequirements.length}/${OTHER_REQUIREMENTS_LENGTH} characters`}
      />
      {matchingRequirements.length > 0 && (
        <>
          <Typography variant="h4">My supported requirements:</Typography>
          <Box>
            <List>
              {matchingRequirements.map(({id, name}) => {
                const labelId = `matching-requirement-${id}`;
                return (
                  <ListItem button key={labelId} onClick={() => handleToggleRequirementId(id)}>
                    <ListItemText id={labelId} primary={name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleToggleRequirementId(id)}
                        checked={newVisitRequirementIds.includes(id)}
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}
      {otherRequirements.length > 0 && (
        <>
          <Typography variant="h4">Other assistance offered:</Typography>
          <Box>
            <List>
              {otherRequirements.map(({id, name}) => {
                const labelId = `requirement-${id}`;
                return (
                  <ListItem button key={labelId} onClick={() => handleToggleRequirementId(id)}>
                    <ListItemText id={labelId} primary={name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleToggleRequirementId(id)}
                        checked={newVisitRequirementIds.includes(id)}
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      )}
      <div>
        <BackLink to={backLinkTo} />
        <Button
          variant="contained"
          color="primary"
          sx={{ml: 1}}
          onClick={handleSubmitVisit}
          disabled={submitVisitMutation.isLoading}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default VisitRequirements;
