import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import {useAuth} from '../context/WebAppAuthProvider';
import {useVenue} from '../WebApp/WebAppVenueProvider';

const FavouriteVenueCheckbox = ({venue, showLabel = true}) => {
  const {user} = useAuth();
  const {toggleFavouriteVenue} = useVenue();
  if (user === undefined) {
    // If we're not logged in, don't render anything
    return;
  }

  const {favourite_stores: favouriteVenues} = user;
  const isChecked = !!favouriteVenues.find((favouriteVenue) => favouriteVenue.id === venue.id);
  const label = isChecked ? 'Remove from favourites' : 'Add to favourites';

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            icon={<FavoriteBorderIcon />}
            checkedIcon={<FavoriteIcon />}
            edge="end"
            onClick={(event) => event.stopPropagation()} // avoid clicking venue list item
            onChange={() => toggleFavouriteVenue(venue.id)}
            checked={isChecked}
            color="primary"
            aria-label={label}
          />
        }
        // The horizontal margins of the checkbox are ignored
        // So add more on the label
        sx={{mr: showLabel ? 1 : 0}}
        labelPlacement="start"
        label={showLabel ? label : null}
      />
    </FormGroup>
  );
};

export default FavouriteVenueCheckbox;
