import {List, ListItem, ListItemButton, ListItemText, Typography} from '@mui/material';

import Loading from '../components/Loading';
import useAllDisabilities from '../Queries/useAllDisabilities';
import ListCheckbox from './ListCheckbox';

import {useAuth} from '../context/WebAppAuthProvider';

const DisabilitiesList = ({selectedDisabilities = [], setSelectedDisabilities}) => {
  const {useCMSEndpoints} = useAuth();
  const selectedDisabilityIDs = selectedDisabilities.map(({id}) => id);
  const {isLoading, data: allDisabilities, isError} = useAllDisabilities(useCMSEndpoints);

  const handleToggleRequirement = (toggleRequirement) => {
    if (selectedDisabilityIDs.includes(toggleRequirement.id)) {
      setSelectedDisabilities(
        selectedDisabilities.filter((requirement) => requirement.id !== toggleRequirement.id),
      );
    } else {
      setSelectedDisabilities([...selectedDisabilities, toggleRequirement]);
    }
  };

  return isLoading ? (
    <Loading padding={false} />
  ) : isError ? (
    <Typography>Error loading - please reload and try again</Typography>
  ) : allDisabilities ? (
    <List data-testid="disabilities-list">
      {allDisabilities.map((disability) => {
        const {id, name} = disability;
        const labelId = `disability-${id}`;
        return (
          <ListItem key={labelId} disablePadding>
            <ListItemButton onClick={() => handleToggleRequirement(disability)}>
              <ListItemText id={labelId} primary={name} />
              <ListCheckbox selected={selectedDisabilityIDs.includes(id)} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  ) : null;
};

export default DisabilitiesList;
