import {Button, List} from '@mui/material';
import {Link} from 'react-router-dom';
import {useOrg} from '../context/OrgProvider';
import AdminList from './AdminList';
import OrgListItem from './OrgListItem';

const AdminOrgList = ({onClick}) => {
  const {fetchOrganisations} = useOrg();
  return (
    <AdminList
      fetchItems={fetchOrganisations}
      title={'Organisations'}
      titleId="admin-org-list-title"
      searchPlaceholder={'Search for an organisation'}
      renderItems={(organisations) => (
        <List>
          {organisations.data.map((organisation) => (
            <OrgListItem
              organisation={organisation}
              key={`organisation-${organisation.id}`}
              onClick={onClick}
            />
          ))}
        </List>
      )}
      headerActions={
        <Button variant="contained" component={Link} to="/organisations/create">
          + Add New Organisation
        </Button>
      }
    />
  );
};

export default AdminOrgList;
