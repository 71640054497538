import {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

import {useAuth} from '../context/WebAppAuthProvider';

// This component is rendered when the user is not authenticated,
// but tries to navigate to an authenticated page
const StoreRedirectUrlForAuth = () => {
  const {setRedirectUrlForAuth} = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  // Store it so we can redirect once we're authenticated.
  useEffect(() => {
    if (location) {
      setRedirectUrlForAuth(location.pathname);
      navigate('/');
    }
  }, [location]);

  return;
};
export default StoreRedirectUrlForAuth;
