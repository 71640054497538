import {createContext, useContext} from 'react';
import {useAuth} from './WebAppAuthProvider';

const VenueListContext = createContext();

const VenueListProvider = ({children, cms = false}) => {
  const {AUTHENTICATED_HEADERS, handleUnauthorized} = useAuth();

  const fetchLists = async (page = 1, searchTerm = '') => {
    const query = new URLSearchParams({
      page,
      // we can send searchString with every request because the API
      // will treat '' as empty
      search_string: searchTerm,
    }).toString();
    const response = await fetch(`/api/cms/venue-lists?${query}`, {headers: AUTHENTICATED_HEADERS});
    return await response.json();
  };

  const fetchList = async (id) => {
    const response = await fetch(cms ? `/api/cms/venue-lists/${id}` : `/api/venue-lists/${id}`, {
      headers: AUTHENTICATED_HEADERS,
    });
    return await response.json();
  };

  const createList = async (venueList) => {
    const response = await fetch('/api/cms/venue-lists/', {
      method: 'POST',
      headers: AUTHENTICATED_HEADERS,
      body: JSON.stringify(venueList),
    });
    if (response.ok) {
      const responseList = await response.json();
      const venueList = await fetchList(responseList.id);
      return venueList;
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't create venue list - please refresh and try again");
        console.error('Failed to create venue list', errors);
      }
    }
  };

  const updateList = async (id, venueList) => {
    const response = await fetch(`/api/cms/venue-lists/${id}`, {
      method: 'PUT',
      headers: AUTHENTICATED_HEADERS,
      body: JSON.stringify(venueList),
    });
    if (response.ok) {
      const venueList = await fetchList(id);
      return venueList;
    } else {
      if (!handleUnauthorized(response)) {
        const errors = await response.json();
        alert("Couldn't update venue list - please refresh and try again");
        console.error('Failed to update venue list', errors);
      }
    }
  };

  return (
    <VenueListContext.Provider value={{fetchLists, fetchList, createList, updateList}}>
      {children}
    </VenueListContext.Provider>
  );
};

export const useVenueList = () => useContext(VenueListContext);
export default VenueListProvider;
