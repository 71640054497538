import {useState} from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../context/WebAppAuthProvider';
import {defaultFormSpacingFactor} from '../constants';
import FileDrop from '../components/FileDrop';
import TooltipIcon from '../components/TooltipIcon';
import BackLink from '../components/BackLink';

const ProfileChangePicture = () => {
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState();
  const {updateProfilePicture} = useAuth();

  const handleSave = async () => {
    const response = await updateProfilePicture(profilePicture);
    if (response.ok) {
      alert('Profile picture saved.');
      navigate(-1);
    } else {
      alert('There was a problem updating your profile picture.');
      console.error(response);
    }
  };
  return (
    <>
      <BackLink />
      <Box mb={defaultFormSpacingFactor}>
        <Typography variant="subtitle1">
          Profile Picture
          <TooltipIcon
            text={
              'This image will be used for your profile so that venues can recognise you. The optimum size is 100x100px'
            }
            anchorElID="user-profile-picture"
          />
        </Typography>
      </Box>
      <Typography variant="subtitle2">Click box or drag file to add image</Typography>
      <FileDrop onFileUpdate={(result) => setProfilePicture(result)} uploadButtonVisible={false} />
      <Box style={{marginTop: '5px'}}>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={!profilePicture}>
          Save
        </Button>
      </Box>
    </>
  );
};

export default ProfileChangePicture;
