import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import {useNewVisit} from './NewVisitProvider';
import AppointeddWidget from './AppointeddWidget';
import AppointeddEnterpriseWidget from './AppointeddEnterpriseWidget';
import BackLink from '../components/BackLink';
import Loading from '../components/Loading';
import TimePicker from '../components/TimePicker';
import useVenueById from './Queries/useVenueById';
import PageHeading from '../components/PageHeading';

const VisitDateTime = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: venue, isLoading, error} = useVenueById(id);
  const {
    newVisitDate,
    minVisitDate,
    setNewVisitDate,
    newVisitTime,
    minVisitTime,
    setNewVisitTime,
    handleDateChange,
    previousDateWasEntered,
    resetNewVisitState,
  } = useNewVisit();
  const [appointeddBookingConfirmed, setAppointeddBookingConfirmed] = useState(false);

  // ensure we have fresh new visit state for this visit
  useEffect(resetNewVisitState, []);

  const appointeddUrl = venue?.appointedd_widget_url;
  const isEnterpriseWidget = appointeddUrl?.includes('retailwidget.appointedd.com');

  // Validation issues:
  const dateTimeError = previousDateWasEntered
    ? 'Please ensure your visit date is in the future'
    : newVisitTime === undefined
      ? 'There are no available bookings slots for this date'
      : null;

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <Typography variant="h3" sx={{mb: 4}}>
        Your visit to {venue.name}
      </Typography>
      {venue && (
        <>
          {appointeddUrl ? (
            <>
              {isEnterpriseWidget ? (
                <AppointeddEnterpriseWidget
                  appointeddUrl={appointeddUrl}
                  handleBookingEvent={(bookingDate, bookingTime) => {
                    setNewVisitDate(bookingDate);
                    setNewVisitTime(bookingTime);
                    setAppointeddBookingConfirmed(true);
                  }}
                />
              ) : (
                <AppointeddWidget
                  appointeddUrl={appointeddUrl}
                  handleBookingEvent={(bookingDate, bookingTime) => {
                    setNewVisitDate(bookingDate);
                    setNewVisitTime(bookingTime);
                    setAppointeddBookingConfirmed(true);
                  }}
                />
              )}
              {appointeddBookingConfirmed && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{margin: '10px'}}
                  onClick={() => {
                    if (venue.questions.length > 0) {
                      navigate(`/venues/${venue.id}/arrange-visit/questions`);
                    } else {
                      navigate(`/venues/${venue.id}/arrange-visit/requirements`);
                    }
                  }}>
                  Next
                </Button>
              )}
              <BackLink to={`/venues/${venue.id}`} />
            </>
          ) : (
            <div style={{marginTop: '20px', display: 'flex', flex: '1', flexDirection: 'column'}}>
              <PageHeading>When would you like to visit?</PageHeading>
              <Typography variant="h6" component="label" htmlFor="visit-date">
                Date:
              </Typography>
              <TextField
                id="visit-date"
                type="date"
                required
                value={newVisitDate}
                onChange={(event) => {
                  setNewVisitDate(event.target.value);
                  if (newVisitTime === undefined) {
                    handleDateChange();
                  }
                }}
                inputProps={{
                  min: minVisitDate,
                }}
                error={!!dateTimeError}
                helperText={dateTimeError}
              />
              <Typography variant="h6" id="visit-time">
                Time:
              </Typography>
              <TimePicker
                labelId="visit-time"
                value={newVisitTime}
                noticePeriodHours={venue.visit_notice_period_hours}
                setValue={(value) => setNewVisitTime(value)}
                newVisitDate={newVisitDate}
                opening_hours={venue?.opening_hours}
                currentTime={minVisitTime}
              />
              <Button
                variant="contained"
                color="primary"
                style={{margin: '10px'}}
                disabled={!!dateTimeError}
                onClick={() => {
                  if (venue.questions.length > 0) {
                    navigate(`/venues/${venue.id}/arrange-visit/questions`);
                  } else {
                    navigate(`/venues/${venue.id}/arrange-visit/requirements`);
                  }
                }}>
                Next
              </Button>
              <BackLink to={`/venues/${venue.id}`} />
            </div>
          )}
        </>
      )}
      {error && (
        <Typography style={{marginTop: '20px'}} component="p" variant="body1">
          Venue not found.
        </Typography>
      )}
    </div>
  );
};

export default VisitDateTime;
