import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// This component is used to render non-interactive checkboxes in list
// to avoid creating a11y issues by nesting interactive components
const ListCheckbox = ({selected}) =>
  selected ? (
    <CheckBoxIcon color="primary" role="checkbox" aria-checked="true" />
  ) : (
    <CheckBoxOutlineBlankIcon role="checkbox" aria-checked="false" />
  );

export default ListCheckbox;
