import {useState, useEffect} from 'react';

import {Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';

import FileDrop from '../components/FileDrop';
import FormControlLabel from '@mui/material/FormControlLabel';

import {useAuth} from '../context/WebAppAuthProvider';
import ValidatingForm from '../components/ValidatingForm';
import FormSectionNumber from '../components/FormSectionNumber';
import TooltipIcon from '../components/TooltipIcon';
import DisabilitiesList from '../components/DisabilitiesList';
import RequirementsList from '../components/RequirementsList';
import {defaultFormSpacingFactor} from '../constants';
import AuthPanel from '../components/AuthPanel';
import PageHeading from '../components/PageHeading';

const WebAppSignUp = () => {
  const {search} = useLocation();
  const params = new URLSearchParams(search);
  const paramName = params.get('name');
  const paramEmail = params.get('email');
  const {register} = useAuth();
  const navigate = useNavigate();
  const numRegistrationSteps = 4;
  const [registrationStep, setRegistrationStep] = useState(1);
  const buttonText = registrationStep < numRegistrationSteps ? 'Next' : 'Sign up';

  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldValues, setFieldValues] = useState({
    name: paramName ?? '',
    phone: '',
    email: paramEmail ?? '',
    password: '',
    passwordConfirmation: '',
    requirements: [],
    disabilities: [],
    marketingConsent: false,
    acceptedPrivacyPolicy: false,
  });

  const [profilePicture, setProfilePicture] = useState();

  useEffect(() => {
    const lengthRegex = /^.{8,20}$/; // must contain minimum of 8 characters
    const oneDigitRegex = /[0-9]/; // must contain at least one digit
    if (fieldValues.password.length) {
      if (!lengthRegex.test(fieldValues.password)) {
        setFieldErrors({
          ...fieldErrors,
          password: `Must be a minimum of 8 characters and maximum of 20 characters.`,
        });
      }

      if (!oneDigitRegex.test(fieldValues.password)) {
        setFieldErrors({
          ...fieldErrors,
          password: 'Must contain at least one number',
        });
      }
    }
  }, [fieldValues.password]);

  useEffect(() => {
    if (fieldValues.passwordConfirmation !== fieldValues.password) {
      setFieldErrors({
        ...fieldErrors,
        passwordConfirmation: 'Passwords do not match.',
      });
    }
  }, [fieldValues.passwordConfirmation]);

  const handleFormSubmit = async () => {
    try {
      const {
        name,
        phone,
        email,
        password,
        passwordConfirmation,
        requirements,
        disabilities,
        marketingConsent,
        acceptedPrivacyPolicy,
      } = fieldValues;
      const response = await register(
        name,
        phone,
        email,
        password,
        passwordConfirmation,
        requirements,
        disabilities,
        profilePicture,
        marketingConsent,
        acceptedPrivacyPolicy,
      );
      if (!response.ok) {
        let errorMsg = 'There was a problem during registration: ';
        const responseJson = await response.json();
        console.error(responseJson);
        if (responseJson.message) {
          errorMsg = `${errorMsg} ${responseJson.message}`;
        }
        alert(errorMsg);
      } else {
        navigate('/', {
          state: {
            didRegister: true,
          },
        });
      }
    } catch (error) {
      alert('There was a problem during registration, please try again.');
      console.error(error);
    }
  };

  const handleToggleMarketingConsent = () => {
    setFieldValues({
      ...fieldValues,
      marketingConsent: !fieldValues.marketingConsent,
    });
  };

  const handleTogglePrivacyPolicy = () => {
    setFieldValues({
      ...fieldValues,
      acceptedPrivacyPolicy: !fieldValues.acceptedPrivacyPolicy,
    });
  };

  const isFinalRegistrationStep = registrationStep === numRegistrationSteps;
  const handleFormButton = (event) => {
    event.preventDefault();
    if (!fieldValues.acceptedPrivacyPolicy) {
      alert('Please accept the privacy policy to continue.');
      return;
    }
    if (isFinalRegistrationStep) {
      handleFormSubmit();
      return;
    }
    if (registrationStep === 1) {
      let didFailValidation = false;
      Object.values(fieldValues).forEach((value) => {
        if (value === '' || value === undefined) {
          didFailValidation = true;
        }
      });

      if (didFailValidation) {
        alert('Please complete all fields to continue.');
        return;
      }
    }
    if (registrationStep === 2 && fieldValues.disabilities.length === 0) {
      alert('Please select at least one item to continue.');
      return;
    }
    if (registrationStep === 3 && fieldValues.requirements.length === 0) {
      alert('Please select at least one item to continue.');
      return;
    }
    setRegistrationStep(registrationStep + 1);
  };

  const hasValidationErrors = Object.values(fieldErrors).find((value) => value !== false);

  return (
    <AuthPanel>
      <ValidatingForm
        onSubmit={handleFormButton}
        initialFieldValues={fieldValues}
        onFieldChange={(name, value) => setFieldValues({...fieldValues, [name]: value})}
        onFieldValidationChange={(name, error) => setFieldErrors({...fieldErrors, [name]: error})}>
        {/* 
            Registration step 1
          */}
        {registrationStep === 1 && (
          <>
            <Box mb={defaultFormSpacingFactor}>
              <PageHeading>Sign Up</PageHeading>
              <Typography variant="subtitle1" paragraph>
                Already have an account?{' '}
                <Link component={RouterLink} to="/login" color="primary" underline="hover">
                  Login
                </Link>
              </Typography>
              <FormControl fullWidth>
                <Typography component="label" variant="subtitle1" htmlFor="name">
                  Name
                  <TooltipIcon text={'Please enter your name'} anchorElID="name" />
                </Typography>
                <FormSectionNumber number={1} />
                <TextField
                  id="name"
                  name="name"
                  value={fieldValues.name}
                  error={!!fieldErrors.name}
                  required
                />
                <FormHelperText error={!!fieldErrors.name}>{fieldErrors.name}</FormHelperText>
              </FormControl>
            </Box>
            <Box mb={defaultFormSpacingFactor}>
              <FormControl fullWidth>
                <Typography component="label" variant="subtitle1" htmlFor="phone">
                  Phone Number
                  <TooltipIcon text={'Please enter your phone number'} anchorElID="phone" />
                </Typography>
                <FormSectionNumber number={2} />
                <TextField
                  id="phone"
                  name="phone"
                  type="tel"
                  minLength={11}
                  maxLength={11}
                  value={fieldValues.phone}
                  error={!!fieldErrors.phone}
                  required
                />
                <FormHelperText error={!!fieldErrors.phone}>{fieldErrors.phone}</FormHelperText>
              </FormControl>
            </Box>
            <Box mb={defaultFormSpacingFactor}>
              <FormControl fullWidth>
                <Typography component="label" variant="subtitle1" htmlFor="email">
                  Email
                  <TooltipIcon text={'Please enter your email'} anchorElID="email" />
                </Typography>
                <FormSectionNumber number={3} />
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  value={fieldValues.email}
                  error={!!fieldErrors.email}
                  required
                />
                <FormHelperText error={!!fieldErrors.email}>{fieldErrors.email}</FormHelperText>
              </FormControl>
            </Box>
            <Box mb={defaultFormSpacingFactor}>
              <FormControl fullWidth>
                <Typography component="label" variant="subtitle1" htmlFor="password">
                  Password
                  <TooltipIcon
                    text={'Create a password. Must be 8-20 characters long and contain 1 number.'}
                    anchorElID="password"
                  />
                </Typography>
                <FormSectionNumber number={4} />
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  value={fieldValues.password}
                  error={!!fieldErrors.password}
                  autoComplete="off"
                  required
                />
                <FormHelperText error={!!fieldErrors.password}>
                  {fieldErrors.password}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <Typography component="label" variant="subtitle1" htmlFor="passwordConfirmation">
                  Confirm Password
                  <TooltipIcon
                    text={'Confirm your new password'}
                    anchorElID="passwordConfirmation"
                  />
                </Typography>
                <TextField
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  value={fieldValues.passwordConfirmation}
                  error={!!fieldErrors.passwordConfirmation}
                  autoComplete="off"
                  required
                />
                <FormHelperText error={!!fieldErrors.passwordConfirmation}>
                  {fieldErrors.passwordConfirmation}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box mb={defaultFormSpacingFactor}>
              <FormControl fullWidth>
                <Typography component="label" variant="subtitle1" htmlFor="marketingEmails">
                  Privacy
                  <TooltipIcon
                    text={
                      'Confirm that you consent to the privacy policy, and receiving marketing emails from Neatebox.'
                    }
                    anchorElID="marketingEmails"
                  />
                </Typography>
                <FormSectionNumber number={5} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fieldValues.marketingConsent}
                      onChange={handleToggleMarketingConsent}
                      name="marketingConsentCheckbox"
                    />
                  }
                  label={<Typography>I consent to receiving marketing emails</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fieldValues.acceptedPrivacyPolicy}
                      onChange={handleTogglePrivacyPolicy}
                      name="privacyPolicyCheckbox"
                      required
                    />
                  }
                  label={
                    <Typography>
                      I agree with the{' '}
                      <a
                        target="_blank"
                        href="https://www.wel-co.me/terms-and-conditions-user"
                        rel="noreferrer">
                        Terms and Conditions
                      </a>{' '}
                      and{' '}
                      <a
                        target="_blank"
                        href="https://www.wel-co.me/privacy-policy"
                        rel="noreferrer">
                        Privacy Policy.
                      </a>
                    </Typography>
                  }
                />
              </FormControl>
            </Box>
          </>
        )}
        {/* 
            Registration step 2
          */}
        {registrationStep === 2 && (
          <>
            <Typography variant="h3">
              In which areas do you need customer service team awareness?
            </Typography>
            <Typography variant="subtitle1">
              You can select more than one. Need help? Contact us at{' '}
              <a href="mailto:support@wel-co.me">support@wel-co.me</a>
            </Typography>
            <Box mb={defaultFormSpacingFactor}>
              <DisabilitiesList
                selectedDisabilities={fieldValues.disabilities}
                setSelectedDisabilities={(disabilities) =>
                  setFieldValues({...fieldValues, disabilities})
                }
              />
            </Box>
          </>
        )}
        {/* 
            Registration step 3
          */}
        {registrationStep === 3 && (
          <>
            <Typography variant="h3">What are your specific service requirements?</Typography>
            <Typography variant="subtitle1">
              You can select more than one. Need help? Contact us at{' '}
              <a href="mailto:support@wel-co.me">support@wel-co.me</a>
            </Typography>
            <Box mb={defaultFormSpacingFactor}>
              <RequirementsList
                selectedRequirements={fieldValues.requirements}
                setSelectedRequirements={(requirements) =>
                  setFieldValues({
                    ...fieldValues,
                    requirements,
                  })
                }
              />
            </Box>
          </>
        )}
        {/* 
            Registration step 4 
          */}
        {registrationStep === 4 && (
          <>
            <Box mb={defaultFormSpacingFactor}>
              <Typography variant="subtitle1">
                Profile Picture
                <TooltipIcon
                  text={
                    'This image will be used for your profile so that venues can recognise you. The optimum size is 100x100px'
                  }
                  anchorElID="user-profile-picture"
                />
              </Typography>
            </Box>
            <Typography variant="subtitle2">Click box or drag file to add image</Typography>
            <FileDrop
              onFileUpdate={(result) => setProfilePicture(result)}
              uploadButtonVisible={false}
            />
            <FormHelperText error={!!fieldErrors.userProfilePicture}>
              {fieldErrors.userProfilePicture}
            </FormHelperText>
          </>
        )}
        {registrationStep > 1 && (
          <Button
            variant="outlined"
            color="primary"
            style={{marginRight: 20}}
            onClick={() => {
              setRegistrationStep(registrationStep - 1);
            }}>
            Back
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={hasValidationErrors}
          onClick={handleFormButton}
          type={isFinalRegistrationStep ? 'submit' : 'button'}>
          {buttonText}
        </Button>
      </ValidatingForm>
    </AuthPanel>
  );
};

export default WebAppSignUp;
