import {ListItem, ListItemText, Typography, ListItemButton} from '@mui/material';
import ListCheckbox from './ListCheckbox';

const GroupAddVenueListItem = ({venue, isAdded, handleClick}) => {
  return (
    <ListItem divider disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary={venue.name}
          secondary={
            <Typography component="span" variant="body1">
              {`${venue.address_1}, ${venue.address_2}, ${venue.town}`}
            </Typography>
          }
        />
        <ListCheckbox selected={isAdded} />
      </ListItemButton>
    </ListItem>
  );
};

export default GroupAddVenueListItem;
