import {useState, useRef} from 'react';

const useClipboard = (text, small = false) => {
  const [hasCopied, setHasCopied] = useState(false);
  const copyRef = useRef(null);
  const handleCopy = () => {
    if (copyRef.current) {
      if (copyRef.current.select) {
        // element is contenteditable (e.g TextField) - use select()
        copyRef.current.select();
        navigator.clipboard.writeText(text);
      } else if (copyRef.current.innerText) {
        // element is not editable - grab innerText
        navigator.clipboard.writeText(copyRef.current.innerText);
      }
      setHasCopied(true);
      setTimeout(() => {
        setHasCopied(false);
      }, 3000);
    }
  };
  const buttonText = !hasCopied ? (small ? 'Copy' : 'Copy to Clipboard') : 'Copied!';
  return {handleCopy, copyRef, buttonText};
};

export default useClipboard;
