import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import {TextField} from '@mui/material';

import NewTabIcon from '../components/NewTabIcon';
import useClipboard from '../hooks/useClipboard';

const EmbedDialog = ({open, onClose, url, noun}) => {
  const code = `<iframe width="100%" height="700px" src="${url}"></iframe>`;
  const {handleCopy, copyRef, buttonText} = useClipboard(code);

  return (
    <Dialog open={open} onClose={onClose} data-testid="embed-dialog">
      <DialogTitle>{`Embed Your ${noun}'s Listing`}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant="outlined">
          <Typography variant="subtitle1" component="div">
            {`You can embed your ${noun}'s WelcoMe listing on your website.`}
          </Typography>
          <Typography variant="subtitle1" component="div">
            Copy and paste the following code and include on your website:
          </Typography>
          <TextField
            label="Embed Code"
            aria-label="Embed Code"
            type="text"
            value={code}
            readOnly
            inputRef={copyRef}
            onClick={handleCopy}
            multiline
            fullWidth
          />
          <div style={{display: 'flex'}}>
            <Button
              component="a"
              href={
                'https://helpdesk.wel-co.me/en/article/how-to-add-the-welcome-widget-to-my-website-181n6pr/'
              }
              target="blank"
              variant="outlined"
              color="primary"
              onClick={() => {}}
              style={{marginRight: 'auto'}}>
              More info
              <NewTabIcon />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{marginLeft: 'auto'}}
              onClick={handleCopy}>
              {buttonText}
            </Button>
          </div>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmbedDialog;
