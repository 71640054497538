import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useVenueById = (venueId) =>
  useQuery({
    queryKey: [`venue-${venueId}`],
    queryFn: () => axios.get(`/api/stores/${venueId}`).then((res) => res.data),
    staleTime: Infinity,
  });

export default useVenueById;
