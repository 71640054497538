import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  FormGroup,
  FormLabel,
  Link,
  Rating,
  TextField,
  Typography,
  Box,
} from '@mui/material';

import BackLink from '../components/BackLink';
import Loading from '../components/Loading';
import PageBackgroundHeader from '../components/PageBackgroundHeader';
import PageHeading from '../components/PageHeading';
import {FINAL_FEEDBACK_QUESTION} from '../constants';
import {useFeedback} from '../context/FeedbackProvider';
import useVisitById from './Queries/useVisitById';

const FEEDBACK_COMMENT_LENGTH = 500;

const VisitFeedbackForm = () => {
  const {id} = useParams();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {data: visit} = useVisitById(id);
  const [responses, setResponses] = useState();
  const {fetchQuestions, submitFeedback} = useFeedback();

  useEffect(() => {
    const init = async () => {
      const questions = await fetchQuestions();
      setResponses(
        [
          ...questions,
          ...visit.store.feedback_questions.sort((a, b) => a.order - b.order),
          // Add the standard qualitative question
          {
            question_text: FINAL_FEEDBACK_QUESTION,
            venue_id: visit.store.id,
          },
        ].map((question) => {
          if (visit.feedback.length === 0) {
            return {
              question,
              rating: null,
              text_response: null,
            };
          } else {
            // If we've already left feedback then copy in our response
            const existingResponse = visit.feedback.find(
              (eachFeedback) =>
                eachFeedback.feedback_question_id === question.id ||
                eachFeedback.feedback_question_id === null,
            );
            return {
              question,
              rating: existingResponse.rating,
              text_response: existingResponse.text_response,
            };
          }
        }),
      );
      setIsLoading(false);
    };
    if (visit) {
      init();
    }
  }, [visit]);

  const hasExistingFeedback = visit?.feedback.length > 0;

  return (
    <>
      <PageBackgroundHeader />
      <BackLink to={`/visits/${id}`} />
      <Box display="flex" justifyContent="space-between" alignItems="baseline" sx={{mb: 2}}>
        <PageHeading>Visit Feedback</PageHeading>
      </Box>
      {isLoading ? (
        <Loading />
      ) : (
        <Card>
          <CardContent>
            {!hasSubmitted ? (
              <>
                <Typography variant="h2">
                  {hasExistingFeedback ? "Here's your feedback" : "We'd like to hear"} about your
                  visit to {visit.store.name} on {visit.arrival.split(' ')[0]}.
                </Typography>
                {responses.map((response, index) => {
                  const questionInputId = `feedback-question-${index}`;
                  return (
                    <Box
                      data-testid="response-row"
                      key={`${response.question.id ?? ''}-${response.question.question_text}`}
                      sx={{mb: 4, width: 1}}
                      display="flex"
                      alignItems="flex-start">
                      <Chip sx={{mr: 1}} label={index + 1} variant={'outlined'} />
                      <FormGroup sx={{width: 1}}>
                        <FormLabel
                          required={!response.question.venue_id}
                          sx={{mb: 1}}
                          htmlFor={questionInputId}>
                          {response.question.question_text}
                        </FormLabel>
                        {/* Render rating or textfield depending on global vs venue-specific question */}
                        {response.question.venue_id ? (
                          <TextField
                            id={questionInputId}
                            name="response-text"
                            fullWidth
                            value={responses[index].text_response ?? ''}
                            disabled={hasExistingFeedback}
                            helperText={
                              hasExistingFeedback
                                ? undefined
                                : `${
                                    responses[index].text_response?.length ?? 0
                                  }/${FEEDBACK_COMMENT_LENGTH} characters`
                            }
                            onChange={(event) =>
                              setResponses(
                                responses.map((eachResponse) =>
                                  eachResponse.question.question_text ===
                                  response.question.question_text
                                    ? {
                                        ...eachResponse,
                                        text_response: event.target.value.slice(
                                          0,
                                          FEEDBACK_COMMENT_LENGTH,
                                        ),
                                      }
                                    : eachResponse,
                                ),
                              )
                            }
                          />
                        ) : (
                          <Rating
                            id={questionInputId}
                            size="large"
                            value={responses[index].rating}
                            disabled={hasExistingFeedback}
                            onChange={(_, newValue) =>
                              setResponses(
                                responses.map((eachResponse) =>
                                  eachResponse.question.question_text ===
                                  response.question.question_text
                                    ? {...eachResponse, rating: newValue}
                                    : eachResponse,
                                ),
                              )
                            }
                          />
                        )}
                      </FormGroup>
                    </Box>
                  );
                })}

                <Box display="flex" justifyContent={'flex-end'}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      setIsLoading(true);
                      const response = await submitFeedback(responses, id);
                      if (response?.ok) {
                        setHasSubmitted(true);
                      }
                      setIsLoading(false);
                    }}
                    disabled={
                      responses.filter(
                        (response) =>
                          // Only questions with null venue_id are required
                          response.question.venue_id === null && response.rating === null,
                      ).length > 0
                    }>
                    Submit
                  </Button>
                </Box>
              </>
            ) : (
              <Container maxWidth="sm" sx={{py: 4, textAlign: 'center'}}>
                <Typography variant="h2" sx={{my: 2}}>
                  Thanks for submitting feedback!
                </Typography>
                <Typography paragraph>
                  The venue will use your feedback to improve experiences for future visitors.
                </Typography>
                <Typography paragraph>
                  If you would like to review the venue further or provide more details, you can
                  find your favourite accessibility review site{' '}
                  <Link href="https://www.wel-co.me/review-sites" target="_blank" rel="noreferrer">
                    here
                  </Link>
                  .
                </Typography>
              </Container>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default VisitFeedbackForm;
