import {useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Switch} from '@mui/material';

import {useAuth} from '../context/WebAppAuthProvider';
import Loading from '../components/Loading';
import ProfilePicture from '../components/ProfilePicture';
import PageBackgroundHeader from '../components/PageBackgroundHeader';
import BackLink from '../components/BackLink';
import SectionHeading from '../components/SectionHeading';
import PageHeading from '../components/PageHeading';
import {getNonEssentialCookieConsent, setNonEssentialCookieConsent} from '../util';

const Profile = () => {
  const {user, updateUserProfile} = useAuth();
  const cookieConsent = getNonEssentialCookieConsent();
  const userHasInteractedWithCookieBanner = cookieConsent !== undefined;

  const [email, setEmail] = useState(user.email);
  const [name, setName] = useState(user.name);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    const response = await updateUserProfile({name, email});
    setIsSaving(false);
    if (response.ok) {
      alert('Changes saved!');
    } else {
      alert('There was a problem updating your details.');
      console.error(response);
    }
  };
  const saveButtonIsDisabled =
    (email === user.email && name === user.name) || // one value must have changed
    email === '' ||
    name === '' || // values can't be empty
    isSaving;

  return (
    <>
      <PageBackgroundHeader />
      <BackLink />
      <PageHeading>My Profile</PageHeading>
      {user ? (
        <Card variant="fullscreen">
          <CardContent>
            <SectionHeading>Account Details</SectionHeading>
            <Box sx={{mb: 1}}>
              {user.profile_picture?.public_url ? (
                <>
                  <ProfilePicture
                    src={user.profile_picture.public_url}
                    alt="Your profile picture"
                  />
                  <Button component={Link} to="/profile/picture" endIcon={<NavigateNextIcon />}>
                    Change Picture
                  </Button>
                </>
              ) : (
                <p>
                  {"You haven't uploaded a profile picture, click "}
                  <Link to="/profile/picture">here</Link>
                  {` `}to add one.
                </p>
              )}
            </Box>

            <FormControl fullWidth>
              <Typography component="label" variant="subtitle1" htmlFor="name">
                Name
              </Typography>
              <TextField
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
                helperText={name === '' ? 'Name is required' : null}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography component="label" variant="subtitle1" htmlFor="email">
                Email Address
              </Typography>
              <TextField
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                helperText={email === '' ? 'Email must be a valid email address' : null}
              />
            </FormControl>

            <SectionHeading>Service Areas & Requirements</SectionHeading>
            <Box sx={{mb: 2}}>
              <Button component={Link} to="/profile/disabilities" endIcon={<NavigateNextIcon />}>
                Areas you would like customer service awareness
              </Button>
            </Box>
            <Box sx={{mb: 2}}>
              <Button component={Link} to="/profile/requirements" endIcon={<NavigateNextIcon />}>
                Accessibility requirements
              </Button>
            </Box>

            {userHasInteractedWithCookieBanner && (
              <>
                <SectionHeading>Cookies</SectionHeading>
                <Box sx={{mb: 3}}>
                  <Typography paragraph>
                    WelcoMe uses cookies for essential functionality, and features such as maps and
                    chat.
                  </Typography>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>Allow non-essential cookies</Typography>
                    <Switch
                      data-testid="cookie-consent-switch"
                      checked={cookieConsent === 'true'}
                      onChange={() => {
                        if (cookieConsent === 'true') {
                          setNonEssentialCookieConsent('false');
                        } else {
                          setNonEssentialCookieConsent('true');
                        }
                        window.location.reload();
                      }}
                      inputProps={{'aria-label': 'Cookie consent switch'}}
                    />
                  </Box>
                </Box>
              </>
            )}

            <Box sx={{mb: 1, textAlign: 'right'}}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={saveButtonIsDisabled}>
                Save Changes
              </Button>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Profile;
