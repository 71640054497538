import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {CMSUserNotificationSettings} from './CMSUserNotificationSettings';
import {useState} from 'react';
import {useUsers} from '../context/UserProvider';
import Loading from './Loading';

const NotificationSettingsDialog = ({user, onClose}) => {
  const {updateUser} = useUsers();
  const [userCopy, setUserCopy] = useState(user);
  const notificationSettings =
    userCopy.organisation_notification_settings ??
    userCopy.venue_group_notification_settings ??
    userCopy.venue_notification_settings[0];
  const [isSaving, setIsSaving] = useState(false);

  const handleSettingUpdate = (settingKey, newValue) => {
    notificationSettings[settingKey] = newValue;
    if (userCopy.organisation_notification_settings) {
      setUserCopy({
        ...userCopy,
        organisation_notification_settings: notificationSettings,
      });
    } else if (userCopy.venue_group_notification_settings) {
      setUserCopy({
        ...userCopy,
        venue_group_notification_settings: notificationSettings,
      });
    } else if (userCopy.venue_notification_settings[0]) {
      setUserCopy({
        ...userCopy,
        venue_notification_settings: [notificationSettings],
      });
    }
  };
  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>Notification Settings</DialogTitle>
      <DialogContent>
        {isSaving ? (
          <Loading />
        ) : (
          <CMSUserNotificationSettings
            notificationSettings={notificationSettings}
            handleSettingUpdate={handleSettingUpdate}
            isEditing={true}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            setIsSaving(true);
            await updateUser(userCopy.id, userCopy);
            setIsSaving(false);
            onClose();
          }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationSettingsDialog;
