import {useState} from 'react';
import {Dialog, DialogContent} from '@mui/material';

import UserDetailsForm from './UserDetailsForm';
import {useRoles} from '../context/RoleProvider';
import {useUsers} from '../context/UserProvider';

const CreateUserDialog = ({roleName, noun, organisation, group, venue, onClose, onCreate}) => {
  const {createUser} = useUsers();
  const {allRoles} = useRoles();
  const role = allRoles.find((role) => role.name === roleName);
  const defaultNotificationSettings = {
    email_notifications: false,
    phone_booking: false,
    phone_arrived: false,
  };
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    phone_extension: '',
    phone_extension_delay: '',
    disabilities: [],
    profile_picture: null,
    requirements: [],
    role: role,
    roles: [role],
    ...(group && roleName === 'venue-group-admin'
      ? {
          venue_group_id: group.id,
          venue_group: group,
          venue_group_notification_settings: {
            ...defaultNotificationSettings,
          },
        }
      : venue && (roleName === 'store-admin' || roleName === 'store-user')
        ? {
            store_id: venue.id,
            store: venue,
            venue_notification_settings: [
              {
                ...defaultNotificationSettings,
              },
            ],
          }
        : organisation && roleName === 'organisation-admin'
          ? {
              organisation_id: organisation.id,
              organisation: organisation,
              organisation_notification_settings: {
                ...defaultNotificationSettings,
              },
            }
          : {}),
  });
  const handleSave = async (newProfilePicture) => {
    const newUser = await createUser(user, newProfilePicture);
    if (newUser) {
      await onCreate(newUser);
    }
  };
  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
      data-testid="create-group-admin-dialog"
      aria-labelledby="user-details-form-title">
      <DialogContent>
        <UserDetailsForm
          title={`Create ${noun}`}
          user={user}
          setUser={setUser}
          handleSave={handleSave}
          handleCancel={onClose}
          initialIsEditing={true}
          disableRole
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserDialog;
