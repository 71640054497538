import {useParams, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Box} from '@mui/material';

import {useAuth} from '../context/WebAppAuthProvider';
import {useOrg} from '../context/OrgProvider';
import Loading from '../components/Loading';
import {useVenueGroup} from '../context/VenueGroupProvider';
import GroupDetailsForm from '../components/GroupDetailsForm';
import {spaceBottom} from '../useSharedStyles';
import BackLink from '../components/BackLink';

// Used by:
// AdminWebApp - /venue-groups/:id
// OrgWebApp - /groups/:id
// WebApp - /groups/:id
const OrgGroupDetails = () => {
  const {id} = useParams();
  const {cmsType} = useAuth();
  const {fetchOrg} = useOrg();
  const {fetchGroup, updateGroup, deleteGroup} = useVenueGroup();

  const [org, setOrg] = useState({});
  const [group, setGroup] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const refetchGroup = async () => {
    setIsLoading(true);
    const theGroup = await fetchGroup(id);
    setGroup(theGroup);
    // When the group has been fetched, fetch its org so we know which venues we can use
    if (cmsType === 'org' || cmsType === 'admin') {
      setOrg(await fetchOrg(theGroup.organisation_id));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      await refetchGroup();
    };
    init();
  }, []);

  const handleCancel = async () => {
    await refetchGroup();
  };

  const handleSave = async () => {
    const newVenueIds = group.venues.map((venue) => venue.id);
    await updateGroup(group.id, group.name, newVenueIds);
    await refetchGroup();
  };

  const handleDeleteGroup = async (id) => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      await deleteGroup(id);
      navigate(-1);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="baseline" sx={spaceBottom}>
        <BackLink />
      </Box>
      <GroupDetailsForm
        group={group}
        organisation={org}
        setGroup={setGroup}
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={handleDeleteGroup}
        initialIsEditing={false}
      />
    </>
  );
};

export default OrgGroupDetails;
