import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useAllDisabilities = (useCMSEndpoints) => {
  return useQuery({
    queryKey: [`all-disabilities`, useCMSEndpoints],
    queryFn: async () =>
      await axios
        .get(
          useCMSEndpoints
            ? '/api/cms/disabilities?per_page=100&order_by=name'
            : '/api/disabilities?per_page=100&order_by=name',
        )
        .then((res) => res.data.data),
    staleTime: Infinity,
  });
};

export default useAllDisabilities;
