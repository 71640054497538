import {Box} from '@mui/system';

export const Dot = () => (
  <Box
    sx={{
      display: 'inline-block',
      position: 'relative',
      top: '-4px',
      ml: 0.5,
      mr: 1,
      backgroundColor: 'primary.main',
      width: 8,
      height: 8,
      borderRadius: '50%',
    }}
  />
);
