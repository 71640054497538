import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';

import {multiline, spaceBottom} from '../useSharedStyles';
import RequirementsList from '../components/RequirementsList';

const ASS_PROVIDED_LENGTH = 1000;

const VenueRequirements = ({
  requirements,
  setRequirements,
  assistanceProvided,
  isEditing = false,
  setAssistanceProvided,
}) => {
  return (
    <>
      <Typography variant="h6" component="label" htmlFor="assistance-provided">
        How Can We Help?
      </Typography>
      {!isEditing ? (
        <Typography variant="body1" sx={{...multiline, ...spaceBottom}}>
          {assistanceProvided}
        </Typography>
      ) : (
        <TextField
          fullWidth
          multiline
          value={assistanceProvided}
          onChange={(event) =>
            setAssistanceProvided(event.target.value.slice(0, ASS_PROVIDED_LENGTH))
          }
          id="assistance-provided"
          name="assistance-provided"
          helperText={`${assistanceProvided.length}/${ASS_PROVIDED_LENGTH} characters`}
        />
      )}
      <Typography variant="h3">Accessibility Features</Typography>
      {isEditing ? (
        <RequirementsList
          selectedRequirements={requirements}
          setSelectedRequirements={setRequirements}
        />
      ) : (
        <List dense sx={spaceBottom}>
          {requirements.map((requirement) => (
            <ListItem key={requirement.id}>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary={`${requirement.name}`} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default VenueRequirements;
