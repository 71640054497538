import {useEffect, useState} from 'react';

import {useParams, useNavigate, Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import GradeIcon from '@mui/icons-material/Grade';

import {indent, spaceBottom} from '../useSharedStyles';
import BackLink from '../components/BackLink';
import ScreenActionContainer from '../components/ScreenActionContainer';
import GoogleMap, {MapWrapper} from '../components/GoogleMap';
import {arrivalToString, isPreviousDate} from '../util';
import Loading from '../components/Loading';
import ManualArrive from './ManualArrive';
import SectionHeading from '../components/SectionHeading';
import VenueEntrances from '../components/VenueEntrances';
import PageBackgroundHeader from '../components/PageBackgroundHeader';
import PageHeading from '../components/PageHeading';
import VisitStatus from '../components/VisitStatus';
import useVisitById from './Queries/useVisitById';
import AnswerVisitQuestions from './components/AnswerVisitQuestions';
import {useNewVisit} from './NewVisitProvider';
import useCancelVisit from './Queries/useCancelVisit';
import useUpdateVisitQuestionResponses from './Queries/useUpdateVisitQuestionResponses';

const ViewVisitDetails = () => {
  const {id: visitId} = useParams();
  const navigate = useNavigate();
  const {
    requiredQuestionsComplete,
    resetNewVisitState,
    newVisitQuestionResponses,
    setQuestionResponse,
  } = useNewVisit();
  const {data: visit, isLoading} = useVisitById(visitId);
  const {store: venue} = visit || {};
  const [manualArriveIsVisible, setManualArriveIsVisible] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  const cancelVisitMutation = useCancelVisit();
  const updateVisitQuestionResponsesMutation = useUpdateVisitQuestionResponses();

  const handleCancelVisit = async () => {
    cancelVisitMutation.mutate(
      {visitId},
      {
        onSuccess: () => {
          alert('Your visit has been cancelled.');
          navigate('/visits');
        },
        onError: (error) => {
          console.error('cancel visit error', error);
          alert(
            'There was a problem cancelling the visit, please try again or use the contact information provided.',
          );
        },
      },
    );
  };

  const visitIsPreviousDate = visit ? isPreviousDate(new Date(visit.arrival)) : false;
  const venueIsDeleted = !!venue?.deleted_at ?? false;
  const going = visit ? (
    <>
      <Typography variant="subtitle1">
        {visitIsPreviousDate ? 'Visited' : 'Going'}:{' '}
        {arrivalToString(visit.arrival, visit.arrival_end)}
      </Typography>
      <VisitStatus visitStatus={visit.status} />
    </>
  ) : null;
  const visitFeedbackExists = visit?.feedback.length > 0;

  // Workaround - AnswerVisitQuestions uses VisitProvider state for question responses
  // make sure it's empty when we arrive on this screen
  useEffect(resetNewVisitState, []);

  return isLoading || !visit || !venue ? (
    <Loading />
  ) : (
    <>
      <PageBackgroundHeader />
      <BackLink to="/visits" />
      <Box display="flex" justifyContent="space-between" alignItems="baseline" sx={spaceBottom}>
        <div>
          <PageHeading>Visit</PageHeading>
          {isMd && going}
        </div>
        <ScreenActionContainer>
          <div style={{textAlign: 'right'}}>
            <Button
              variant="contained"
              color="primary"
              style={{}}
              onClick={() => setManualArriveIsVisible(!manualArriveIsVisible)}>
              Arrived
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{marginLeft: theme.spacing(1)}}
              onClick={handleCancelVisit}>
              {`Cancel${isMd ? ' Visit' : ''}`}
            </Button>
            {manualArriveIsVisible && <ManualArrive venue={venue} visitId={visitId} />}
          </div>
        </ScreenActionContainer>
      </Box>
      {!isMd && going}
      {visitIsPreviousDate && (
        <Box sx={{my: 2}}>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to={`/feedback/${visit.id}`}
            endIcon={<GradeIcon />}>
            {visitFeedbackExists ? 'View my' : 'Leave'} visit feedback
          </Button>
        </Box>
      )}
      {visit.hasUnansweredVisitQuestions && (
        <Card variant="fullscreen" sx={{mt: 2, mb: 4, borderRadius: 1}}>
          <CardContent>
            <Typography variant="h3">Visit Questions</Typography>
            <Typography paragraph>
              This venue has required visit questions you have not answered yet:
            </Typography>
            <AnswerVisitQuestions
              venue={venue}
              questionResponses={newVisitQuestionResponses}
              setQuestionResponse={setQuestionResponse}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                disabled={!requiredQuestionsComplete(venue)}
                onClick={() => {
                  updateVisitQuestionResponsesMutation.mutate(
                    {visitId, question_responses: newVisitQuestionResponses},
                    {
                      onSuccess: () => {
                        alert('Thank you for answering visit questions.');
                      },
                      onError: (error) => {
                        console.error('update visit question responses error', error);
                        alert('There was a problem updating your responses, please try again');
                      },
                    },
                  );
                }}>
                Submit
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
      <Card variant="fullscreen" sx={{mt: 1}}>
        <CardContent>
          <Typography variant="h2">
            {venue.name}
            {venueIsDeleted ? ' (Deleted)' : null}
          </Typography>
          <Typography variant="body2" sx={spaceBottom}>
            {venue.type}
          </Typography>
          <SectionHeading>Address</SectionHeading>
          <Typography variant="body1">{`${venue.address_1 ?? ''} ${venue.address_2 ?? ''} ${
            venue.address_3 ?? ''
          }`}</Typography>
          <Typography variant="body1" sx={spaceBottom}>
            {venue.town} {venue.postcode}
          </Typography>

          <Box sx={spaceBottom}>
            <MapWrapper requireCookies>
              <GoogleMap
                markerCoordinates={{
                  lat: venue.latitude,
                  lng: venue.longitude,
                }}
                style={{
                  height: 200,
                  pointerEvents: 'none',
                }}
              />
            </MapWrapper>
          </Box>
          {venue.beacons.length > 0 && (
            <>
              <SectionHeading>Venue Entrances</SectionHeading>
              <VenueEntrances beacons={venue.beacons} />
            </>
          )}
          {(visit.requirements.length > 0 || !!visit.other_requirements) && (
            <>
              <SectionHeading>My Requirements</SectionHeading>
              <List sx={spaceBottom} dense>
                {visit.requirements.map((requirement) => (
                  <ListItem key={requirement.id}>
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${requirement.name}`} />
                  </ListItem>
                ))}
              </List>
              {visit.other_requirements && (
                <>
                  <Typography variant="h4">Custom Notes:</Typography>
                  <Typography variant="body1" sx={{...indent, ...spaceBottom}}>
                    {visit.other_requirements}
                  </Typography>
                </>
              )}
            </>
          )}
          {visit.responses?.length > 0 && (
            <>
              <SectionHeading>Question Responses</SectionHeading>
              <List dense>
                {visit.responses.map((response) => (
                  <ListItem key={`response-${response.id}`}>
                    <ListItemIcon>
                      <QuestionAnswerIcon />
                    </ListItemIcon>
                    <div>
                      <Typography variant="h5">{response.question.question_text}</Typography>
                      <Typography variant="body1">- {response.response_text}</Typography>
                    </div>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ViewVisitDetails;
