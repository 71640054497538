import {createContext, useContext, useState} from 'react';
import {useAuth} from '../context/WebAppAuthProvider';

const VenueContext = createContext();

const VenueProvider = ({children}) => {
  const {AUTHENTICATED_HEADERS, getUserInfo} = useAuth();
  const [venues, setVenues] = useState([]);
  const [hasMore, setHasMore] = useState();

  const fetchVenues = async (page, searchString = '', location) => {
    // we can send searchString with every request because the API
    // will treat '' as empty
    const query = new URLSearchParams({
      page,
      search_string: searchString,
      // URLSearchParams encodes undefined as "undefined", so we can't include it at all if it's not set
      ...(location && {location}),
    }).toString();

    const response = await fetch(`/api/stores?${query}`, {
      headers: AUTHENTICATED_HEADERS,
    });
    return response;
  };

  const toggleFavouriteVenue = async (id) => {
    const response = await fetch(`/api/stores/${id}/favourite`, {
      method: 'PUT',
      headers: AUTHENTICATED_HEADERS,
    });
    if (response.ok) {
      // reload user state, so we have the updated list of favourite venues
      await getUserInfo();
      const responseJson = await response.json();
      const {favourited} = responseJson;
      return favourited;
    } else {
      console.error('Error while saving favourite venue');
    }
  };

  const value = {
    venues,
    setVenues,
    fetchVenues,
    hasMore,
    setHasMore,
    toggleFavouriteVenue,
  };
  return <VenueContext.Provider value={value}>{children}</VenueContext.Provider>;
};

const useVenue = () => useContext(VenueContext);

export {VenueProvider, useVenue};
