import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import {Box, Typography} from '@mui/material';

import {useAuth} from '../context/WebAppAuthProvider';
import RequirementsList from '../components/RequirementsList';
import {defaultFormSpacingFactor} from '../constants';
import BackLink from '../components/BackLink';

const ProfileRequirements = () => {
  const navigate = useNavigate();
  const {user, updateUserProfile} = useAuth();
  const [selectedRequirements, setSelectedRequirements] = useState([...user.requirements]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setSelectedRequirements([...user.requirements]);
  }, [user]);

  const handleSave = async () => {
    setIsSaving(true);
    const response = await updateUserProfile({
      requirements: selectedRequirements.map(({id}) => id),
    });
    setIsSaving(false);
    if (response.ok) {
      alert('Changes saved!');
      navigate(-1);
    } else {
      alert('There was a problem saving your details.');
      console.error(response);
    }
  };

  return (
    <>
      <BackLink />
      <Typography variant="h3">What are your specific service requirements?</Typography>
      <Typography variant="subtitle1">
        You can select more than one. Need help? Contact us at{' '}
        <a href="mailto:support@wel-co.me">support@wel-co.me</a>
      </Typography>
      <Box mb={defaultFormSpacingFactor}>
        <RequirementsList
          selectedRequirements={selectedRequirements}
          setSelectedRequirements={(requirements) => setSelectedRequirements(requirements)}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
    </>
  );
};

export default ProfileRequirements;
