import {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

import {spaceBottom} from '../useSharedStyles';
import Loading from '../components/Loading';
import VenueListItem from '../components/VenueListItem';
import PageHeading from '../components/PageHeading';
import {useVenueList} from '../context/VenueListProvider';

const ViewVenueList = () => {
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [venueList, setVenueList] = useState({});
  const venues = venueList?.venues ?? [];
  const {fetchList} = useVenueList();

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      setVenueList(await fetchList(id));
      setIsLoading(false);
    };
    if (id) {
      init();
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="baseline" sx={spaceBottom}>
            <PageHeading>{`${venueList.name} Venues`}</PageHeading>
          </Box>
          <List>
            {venues.map((venue) => (
              <VenueListItem venue={venue} key={`venue-${venue.id}`} />
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default ViewVenueList;
