import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';

const SIDE = 100;

const ProfilePicture = ({src, alt = ''}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: `calc(${SIDE}px + ${theme.spacing(2)})`,
        height: `calc(${SIDE}px + ${theme.spacing(2)})`,
        padding: 1,
        marginBottom: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'stroke.main',
        borderRadius: 0.5,
      }}>
      <img
        src={src}
        style={{
          display: 'block',
          maxWidth: SIDE,
          maxHeight: SIDE,
          width: 'auto',
          height: 'auto',
        }}
        alt={alt}
      />
    </Box>
  );
};

export default ProfilePicture;
