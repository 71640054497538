import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LinkIcon from '@mui/icons-material/Link';

import Loading from '../components/Loading';
import useHelpLinks from './Queries/useHelpLinks';
import PageHeading from '../components/PageHeading';

const HelpLinks = () => {
  const {data: links, isLoading} = useHelpLinks();
  return (
    <>
      <PageHeading>Help Links</PageHeading>
      {isLoading ? (
        <Loading />
      ) : (
        <List>
          {links?.map((link, index) => (
            <ListItem key={`link-${index}`}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText>
                <Link
                  color="primary"
                  href={link.url}
                  underline="hover"
                  target="_blank"
                  rel="noreferrer">
                  {link.name}
                </Link>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default HelpLinks;
