import Box from '@mui/material/Box';

const PageBackgroundHeader = () => (
  <Box
    sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: 'pageHeaderBackground',
      width: '100%',
      height: 400 + 56,
      zIndex: -1,
    }}
  />
);

export default PageBackgroundHeader;
