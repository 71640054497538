import {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import {Box, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../context/WebAppAuthProvider';
import DisabilitiesList from '../components/DisabilitiesList';
import {defaultFormSpacingFactor} from '../constants';
import BackLink from '../components/BackLink';

const ProfileDisabilities = () => {
  const navigate = useNavigate();
  const {user, updateUserProfile} = useAuth();
  const [selectedDisabilities, setSelectedDisabilities] = useState([...user.disabilities]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setSelectedDisabilities([...user.disabilities]);
  }, [user]);

  const handleSave = async () => {
    setIsSaving(true);
    const response = await updateUserProfile({
      disabilities: selectedDisabilities.map(({id}) => id),
    });
    setIsSaving(false);
    if (response.ok) {
      alert('Changes saved!');
      navigate(-1);
    } else {
      alert('There was a problem saving your details.');
      console.error(response);
    }
  };

  return (
    <>
      <BackLink />
      <Typography variant="h3">
        In which areas do you need customer service team awareness?
      </Typography>
      <Typography variant="subtitle1">
        You can select more than one. Need help? Contact us at{' '}
        <a href="mailto:support@wel-co.me">support@wel-co.me</a>
      </Typography>
      <Box mb={defaultFormSpacingFactor}>
        <DisabilitiesList
          selectedDisabilities={selectedDisabilities}
          setSelectedDisabilities={(disabilities) => setSelectedDisabilities(disabilities)}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
    </>
  );
};

export default ProfileDisabilities;
