import {Paper, InputBase, IconButton, Divider} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const SearchInput = ({
  placeholder,
  searchTerm,
  setSearchTerm,
  onSearch = () => {},
  onClear = () => {},
}) => {
  return (
    <Paper
      component="form"
      sx={{
        py: 0.25,
        px: 0.5,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        mr: 2,
      }}>
      <InputBase
        name="search-input"
        onChange={(event) => setSearchTerm(event.target.value)}
        value={searchTerm}
        sx={{ml: 1, flex: 1}}
        placeholder={placeholder}
        inputProps={{'aria-label': placeholder}}
      />
      <IconButton
        name="search-submit"
        type="submit"
        aria-label="search"
        onClick={(event) => {
          event.preventDefault();
          onSearch();
        }}>
        <SearchIcon />
      </IconButton>
      <Divider sx={{height: 28, m: 0.5}} orientation="vertical" />
      <IconButton
        color="primary"
        aria-label="clear"
        onClick={(event) => {
          event.preventDefault();
          onClear();
        }}>
        <ClearIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchInput;
