import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import NewTabIcon from './NewTabIcon';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const VenueLinks = ({links, setLinks, isEditing}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  const addLink = () => {
    setLinks([
      ...links,
      {
        url: 'https://',
        name: '',
      },
    ]);
  };
  const removeLink = (removedLink) => {
    setLinks(links.filter((link) => link !== removedLink));
  };
  const updateLinkAtIndex = (linkIndex, updatedLink) => {
    setLinks(links.map((link, index) => (index === linkIndex ? {...link, ...updatedLink} : link)));
  };
  return (
    <>
      <List dense>
        {links.map((link, index) => (
          <ListItem key={`link-${index}`} sx={{mb: 1}}>
            {!isEditing ? (
              <ListItemText
                primary={link.name}
                secondary={
                  <a href={link.url} target="__blank">
                    {link.url}
                    <NewTabIcon />
                  </a>
                }
              />
            ) : (
              <>
                <Box display="flex" flexDirection={isMd ? 'row' : 'column'} sx={{width: '90%'}}>
                  <TextField
                    name="link-name"
                    inputProps={{
                      'data-testid': `link-${index}-name`,
                    }}
                    label="Name"
                    required
                    value={link.name}
                    sx={{flex: 1, mr: isMd ? 2 : 0}}
                    onChange={(event) =>
                      updateLinkAtIndex(index, {
                        ...link,
                        name: event.target.value,
                      })
                    }
                  />
                  <TextField
                    name="link-url"
                    inputProps={{
                      'data-testid': `link-${index}-url`,
                    }}
                    label="URL"
                    type="url"
                    placeholder="https://www.example.com"
                    required
                    sx={{flex: 1}}
                    value={link.url}
                    onChange={(event) =>
                      updateLinkAtIndex(index, {
                        ...link,
                        url: event.target.value,
                      })
                    }
                  />
                </Box>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeLink(link)}
                    color="primary"
                    style={{marginLeft: 'auto'}}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
        ))}
      </List>
      {isEditing && (
        <Button onClick={addLink} variant="outlined" color="primary">
          Add Link
        </Button>
      )}
    </>
  );
};

export default VenueLinks;
