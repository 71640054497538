import {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

import {spaceBottom} from '../useSharedStyles';
import Loading from '../components/Loading';
import {useOrg} from '../context/OrgProvider';
import {useAuth} from '../context/WebAppAuthProvider';
import VenueListItem from '../components/VenueListItem';
import EmbedDialog from '../CMS/EmbedDialog';
import {getAppLinkForOrgVenues} from '../util';
import ScreenActionContainer from '../components/ScreenActionContainer';
import PageHeading from '../components/PageHeading';

const OrgVenueList = () => {
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [org, setOrg] = useState({});
  const venues = org?.stores ?? [];
  const {fetchOrg} = useOrg();
  const {user, cmsType} = useAuth();
  const [showEmbedModal, setShowEmbedModal] = useState(false);

  useEffect(() => {
    // initial fetch - show loading indicators
    const init = async () => {
      setIsLoading(true);
      setOrg(await fetchOrg(id ?? user.organisation_id));
      setIsLoading(false);
    };
    if (id ?? user?.organisation_id) {
      init();
    }
  }, [id, user?.organisation_id]);

  return (
    <>
      <EmbedDialog
        open={showEmbedModal}
        onClose={() => setShowEmbedModal(false)}
        url={getAppLinkForOrgVenues(user?.organisation_id)}
        noun="Organisation"
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="baseline" sx={spaceBottom}>
            <PageHeading>{`${org.name} Venues`}</PageHeading>
            {cmsType === 'org' && (
              <ScreenActionContainer>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowEmbedModal(true)}>
                  Embed
                </Button>
              </ScreenActionContainer>
            )}
          </Box>
          <List>
            {venues.map((venue) => (
              <VenueListItem venue={venue} key={`venue-${venue.id}`} />
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default OrgVenueList;
