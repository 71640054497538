import {Box} from '@mui/system';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import FileDrop from '../../components/FileDrop';
import {useAuth} from '../../context/WebAppAuthProvider';

const QUESTION_RESPONSE_LENGTH = 140;

const AnswerVisitQuestions = ({venue, questionResponses, setQuestionResponse}) => {
  const {token} = useAuth();
  const uploadFile = async (token, file, question) => {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('question_id', question.id);
    const result = await fetch('/api/files', {
      body: fd,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await result.json();
  };
  return venue?.questions.map((question, index, array) => {
    const response = questionResponses.find(({question_id}) => question_id === question.id);
    const questionID = `question-${question.id}`;
    return (
      <Box key={questionID} sx={{mb: 3}}>
        <Typography variant="h4" gutterBottom>
          {index + 1}. {question.question_text}
          {question.is_required && (
            <Typography
              variant="subtitle2"
              component="label"
              color="primary"
              sx={{ml: 1, verticalAlign: 'top'}}>
              (Required)
            </Typography>
          )}
        </Typography>
        {question.has_attachment && (
          <FileDrop
            style={{marginBottom: 10}}
            onFileUpdate={async (newFile) => {
              const file = await uploadFile(token, newFile, question);
              setQuestionResponse(question.id, {
                file_id: file.id,
              });
            }}
            fileDropAccept={{
              'text/csv': ['.csv'],
              'application/pdf': ['.pdf'],
              'application/msword': ['.doc'],
              'application/vnd.ms-excel': ['.xls'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            }}
          />
        )}
        <TextField
          id={questionID}
          required
          type="text"
          fullWidth
          multiline
          minRows={2}
          maxRows={5}
          value={response?.response_text ?? ''}
          onChange={(event) =>
            setQuestionResponse(question.id, {
              response_text: event.target.value.slice(0, QUESTION_RESPONSE_LENGTH),
            })
          }
          helperText={`${
            response?.response_text?.length ?? 0
          }/${QUESTION_RESPONSE_LENGTH} characters`}
          placeholder="Type your response here"
        />
        {index < array.length - 1 && <Divider sx={{my: 2}} />}
      </Box>
    );
  });
};

export default AnswerVisitQuestions;
