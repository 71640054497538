import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useAllRequirements = (useCMSEndpoints) => {
  return useQuery({
    queryKey: ['all-requirements', useCMSEndpoints],
    queryFn: async () =>
      await axios
        .get(
          useCMSEndpoints
            ? '/api/cms/requirements?per_page=100&order_by=name'
            : '/api/requirements?per_page=100&order_by=name',
        )
        .then((res) => res.data.data),
    staleTime: Infinity,
  });
};

export default useAllRequirements;
