import Box from '@mui/material/Box';

import {defaultFormMargins} from '../constants';

const circleDiameter = 36;

const FormSectionNumber = ({number, formMargins}) => {
  // use negative margins to cancel out the Panel padding
  // and align with the left side of the form
  const margins = formMargins || defaultFormMargins.x;
  const negativeMargins = margins.map((val) => -val);
  return (
    <Box
      mx={negativeMargins}
      style={{
        position: 'relative',
      }}>
      <div
        style={{
          position: 'absolute',
          top: -circleDiameter,
          left: -circleDiameter / 2,
          width: circleDiameter,
          height: circleDiameter,
          borderRadius: '50%',
          textAlign: 'center',
          zIndex: 100,
          backgroundColor: '#EB004E',
          color: 'white',
          fontWeight: 'bold',
          lineHeight: `${circleDiameter}px`,
        }}>
        {number}
      </div>
    </Box>
  );
};
export default FormSectionNumber;
