import {useState} from 'react';
import {FormControlLabel, FormGroup, Switch} from '@mui/material';
import {Box} from '@mui/system';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import {isToday, sortVisits} from '../util';
import VisitRow from '../components/VisitRow';
import Loading from '../components/Loading';
import PageHeading from '../components/PageHeading';
import useUpcomingVisits from './Queries/useUpcomingVisits';
import usePastVisits from './Queries/usePastVisits';

const PastVisits = () => {
  const {data: visits, isLoading} = usePastVisits();
  return isLoading ? (
    <Loading />
  ) : (
    <List>
      {visits.map((visit) => (
        <VisitRow key={`visit-${visit.id}`} visit={visit} />
      ))}
    </List>
  );
};

const UpcomingVisits = () => {
  const {data: upcomingVisits, isLoading} = useUpcomingVisits();
  // Find visits with required questions the user has not answered
  const visitsWithUnansweredQuestions =
    upcomingVisits?.filter((visit) => visit.hasUnansweredVisitQuestions) ?? [];
  const todayVisits = upcomingVisits?.filter((visit) => isToday(new Date(visit.arrival))) ?? [];
  todayVisits.sort(sortVisits);
  const futureVisits = upcomingVisits?.filter((visit) => !isToday(new Date(visit.arrival))) ?? [];
  futureVisits.sort(sortVisits);
  return isLoading ? (
    <Loading />
  ) : (
    <>
      {visitsWithUnansweredQuestions.length > 0 && (
        <>
          <Typography variant="h2">Visits requiring action</Typography>
          <Typography paragraph>
            These visits have questions which have not yet been answered
          </Typography>
          <List sx={{mb: 4}}>
            {visitsWithUnansweredQuestions.map((visit) => (
              <VisitRow key={`visit-${visit.id}`} visit={visit} />
            ))}
          </List>
        </>
      )}
      {todayVisits.length > 0 && (
        <>
          <Typography variant="h2">{"Today's Visits"}</Typography>
          <List sx={{mb: 4}}>
            {todayVisits.map((visit) => (
              <VisitRow key={`visit-${visit.id}`} visit={visit} />
            ))}
          </List>
        </>
      )}
      {futureVisits.length > 0 && (
        <>
          <Typography variant="h2">Upcoming Visits</Typography>
          <List sx={{mb: 4}}>
            {futureVisits.map((visit) => (
              <VisitRow key={`visit-${visit.id}`} visit={visit} />
            ))}
          </List>
        </>
      )}
      {!futureVisits.length > 0 && !todayVisits.length > 0 && (
        <Typography component="span" variant="body1" color="textPrimary">
          No visits booked.
        </Typography>
      )}
    </>
  );
};

const ViewVisits = () => {
  const [isShowingPast, setIsShowingPast] = useState(false);
  return (
    <>
      <Box sx={{mb: 2}}>
        <PageHeading>Your Visits</PageHeading>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isShowingPast}
                value={isShowingPast}
                onChange={() => setIsShowingPast(!isShowingPast)}
              />
            }
            label="Show my previous visits"
          />
        </FormGroup>
      </Box>
      {isShowingPast ? <PastVisits /> : <UpcomingVisits />}
    </>
  );
};

export default ViewVisits;
