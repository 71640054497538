import {useEffect} from 'react';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import {daysSundayLeading} from '../constants';
import {canBookSlot, range} from '../util';

const TimePicker = ({
  value,
  setValue,
  opening_hours,
  noticePeriodHours,
  newVisitDate,
  currentTime,
  labelId,
}) => {
  // Find available slots for the selected date
  const selectedWeekday = daysSundayLeading[new Date(newVisitDate).getDay()];
  const openingHoursForDay = opening_hours?.filter((hour) => hour.day === selectedWeekday);
  const slots = openingHoursForDay
    .map((hour) => {
      // For each hour between open and close, create slots for :00 and :30:
      const [openHour, closeHour] = [+hour.open.split(':')[0], +hour.close.split(':')[0]];
      const hourRange = range(openHour, closeHour);
      return hourRange.map((hour) => [`${hour}:00`, `${hour}:30`]);
    })
    .flat(2);
  const uniqueSlots = [...new Set(slots)]; // Remove duplicates
  // Filter for slots far enough in the future
  const futureSlots = uniqueSlots.filter((slot) => {
    return !currentTime || canBookSlot(slot, currentTime, noticePeriodHours);
  });
  // Filter for slots that fit into the required booking notice period

  // Pick the initial slot
  useEffect(() => {
    if (futureSlots.length === 0) {
      // If there are no slots, set our value to undefined to prevent booking
      setValue(undefined);
    } else if (value && !futureSlots.includes(value)) {
      // If there are slots, pick the closest or first one
      const [valueHour] = value.split(':');
      const matchingSlot = futureSlots.find((slot) => slot.split(':')[0] === valueHour);
      setValue(matchingSlot ?? futureSlots[0]);
    }
  }, [value, futureSlots]);

  return (
    <FormControl>
      <Select
        labelId={labelId}
        value={uniqueSlots.includes(value) ? value : ''}
        onChange={(event) => setValue(event.target.value)}>
        {uniqueSlots.map((eachSlot) => (
          <MenuItem value={eachSlot} key={eachSlot} disabled={!futureSlots.includes(eachSlot)}>
            {eachSlot}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimePicker;
