import {useState} from 'react';
import {
  Button,
  List,
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  Tab,
  Tabs,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import DeleteIcon from '@mui/icons-material/Delete';

import {spaceBottom} from '../useSharedStyles';
import {useAuth} from '../context/WebAppAuthProvider';
import PageHeading from '../components/PageHeading';
import ScreenActionContainer from '../components/ScreenActionContainer';
import VenueListItem from '../components/VenueListItem';
import GroupAddVenueListItem from './GroupAddVenueListItem';
import OrgVenueSearchInput from './OrgVenueSearchInput';
import EmbedDialog from '../CMS/EmbedDialog';
import {getAppLinkForGroupVenues} from '../util';
import PageBackgroundHeader from '../components/PageBackgroundHeader';
import SectionHeading from '../components/SectionHeading';
import CreateUserDialog from './CreateUserDialog';
import {UserList} from './UserList';

const GroupDetailsForm = ({
  group,
  organisation,
  setGroup,
  handleSave,
  handleCancel,
  handleDelete,
  initialIsEditing,
}) => {
  const {cmsType} = useAuth();
  const [isEditing, setIsEditing] = useState(initialIsEditing);
  const [searchTerm, setSearchTerm] = useState('');
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState('general');
  const canEdit = cmsType === 'org' || cmsType === 'admin';
  const [isAddingUser, setIsAddingUser] = useState(false);

  const groupVenueIds = group?.venues?.map(({id}) => id) ?? [];
  const search = searchTerm.toLowerCase();
  const filteredOrgVenues = (organisation?.stores ?? []).filter(
    ({name, address_1, address_2, town, type, category, postcode}) =>
      [name, address_1, address_2, town, type, category, postcode].find((term) =>
        term.toLowerCase().includes(search),
      ),
  );

  const validateAndSave = async () => {
    // TODO - validation
    if (group.name === '') {
      alert('Please enter a group name');
    } else if (group.venues.length === 0) {
      alert('Please select at least one venue');
    } else {
      await handleSave();
      setIsEditing(false);
    }
  };

  const actions = !isEditing ? (
    <div>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => handleDelete(group.id)}
        sx={{mr: 1}}
        endIcon={<DeleteIcon />}>
        Delete
      </Button>
      <Button
        sx={{mr: 1}}
        variant="contained"
        color="secondary"
        onClick={() => setShowEmbedModal(true)}>
        Embed
      </Button>
      <Button variant="contained" color="primary" onClick={() => setIsEditing(!isEditing)}>
        Edit
      </Button>
    </div>
  ) : (
    <div>
      <Box sx={{mb: 1}}>
        <Button
          variant="contained"
          color="secondary"
          onClick={async () => {
            // TODO - this form is rendered in a dialog, in which case we shouldn't warn before cancelling
            const confirmCancel =
              window.confirm(
                "Are you sure you want to cancel? You'll lose any changes you've made.",
              ) === true;
            if (confirmCancel) {
              await handleCancel();
              setIsEditing(false);
            }
          }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            await validateAndSave();
          }}
          sx={{ml: 1}}
          disabled={(group?.venues.length ?? []) === 0 || group.name === ''}>
          Save
        </Button>
      </Box>
    </div>
  );
  return (
    <>
      {isAddingUser && (
        <CreateUserDialog
          roleName="venue-group-admin"
          noun="Group Admin"
          group={group}
          onClose={() => setIsAddingUser(false)}
          onCreate={(newUser) => {
            // TODO - tell parent component to actually re-fetch the group
            setGroup({...group, users: [...group.users, newUser]});
            setIsAddingUser(false);
          }}
        />
      )}
      <EmbedDialog
        open={showEmbedModal}
        onClose={() => setShowEmbedModal(false)}
        url={getAppLinkForGroupVenues(group?.id)}
        noun="Group"
      />
      <PageBackgroundHeader />
      <Box display="flex" justifyContent="space-between" alignItems="baseline" sx={spaceBottom}>
        <div>
          <PageHeading id="group-details-form-title">Venue Group</PageHeading>
        </div>
        {canEdit && <ScreenActionContainer>{actions}</ScreenActionContainer>}
      </Box>
      {canEdit && group.id && (
        <Tabs
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
          indicatorColor="primary"
          textColor="inherit"
          scrollButtons="auto"
          variant="scrollable">
          <Tab label="General" value="general" />
          <Tab label="Users" value="users" />
        </Tabs>
      )}
      <Card variant="fullscreen">
        <CardContent>
          {selectedTab === 'general' ? (
            <>
              {!isEditing ? (
                <Typography variant="h2">{group.name}</Typography>
              ) : (
                <TextField
                  id="group-name"
                  value={group.name}
                  fullWidth
                  sx={{mb: 5, maxWidth: 420}}
                  placeholder="Group name"
                  // TODO - try using sx instead of style, so we can avoid a useTheme()
                  inputProps={{style: {fontSize: theme.typography.h2.fontSize}}}
                  onChange={(event) => setGroup({...group, name: event.target.value})}
                />
              )}
              <Typography variant="h2">Group Venues</Typography>
              {!isEditing && group?.venues ? (
                <List>
                  {group.venues.map((venue) => (
                    <VenueListItem venue={venue} key={`venue-${venue.id}`} />
                  ))}
                </List>
              ) : (
                <>
                  <OrgVenueSearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                  <List sx={{mt: 2}}>
                    {filteredOrgVenues.map((venue) => (
                      <GroupAddVenueListItem
                        key={`venue-${venue.id}`}
                        venue={venue}
                        isAdded={groupVenueIds.includes(venue.id)}
                        handleClick={() => {
                          if (groupVenueIds.includes(venue.id)) {
                            const filteredVenues = group.venues.filter(
                              (filterVenue) => filterVenue.id !== venue.id,
                            );
                            setGroup({...group, venues: filteredVenues});
                          } else {
                            setGroup({...group, venues: [...group.venues, venue]});
                          }
                        }}
                      />
                    ))}
                  </List>
                </>
              )}
            </>
          ) : selectedTab === 'users' ? (
            <>
              <SectionHeading
                button={
                  <Button
                    variant="contained"
                    disabled={isEditing}
                    onClick={() => setIsAddingUser(true)}>
                    + Add new admin
                  </Button>
                }>
                Group Administrators
              </SectionHeading>
              <UserList users={group.users} />
            </>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
};

export default GroupDetailsForm;
