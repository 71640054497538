import {useMutation, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';

const useManualArrive = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({visitId, beaconId}) =>
      axios.post(`/api/attends/${visitId}/manual-arrive`, {beacon_id: beaconId}),
    onSuccess: (_, {visitId}) => {
      queryClient.invalidateQueries({queryKey: ['upcoming-visits']});
      queryClient.invalidateQueries({queryKey: [`visits-${visitId}`]});
    },
  });
};

export default useManualArrive;
