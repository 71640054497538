import {Button, Typography} from '@mui/material';
import CookieConsent from 'react-cookie-consent';

import {isInIFrame} from '../util';

const CookieConsentBanner = () => {
  return isInIFrame ? null : (
    <CookieConsent
      location="bottom"
      ButtonComponent={Button}
      buttonText="All cookies"
      disableButtonStyles
      customButtonProps={{
        variant: 'contained',
        color: 'primary',
        size: 'small',
        sx: {m: 1},
      }}
      enableDeclineButton
      declineButtonText="Essential cookies only"
      customDeclineButtonProps={{
        variant: 'outlined',
        color: 'inherit',
        size: 'small',
        sx: {m: 1},
      }}
      onAccept={() => {
        window.location.reload();
      }}
      onDecline={() => {
        window.location.reload();
      }}>
      <Typography variant="body2" paragraph sx={{color: 'white'}}>
        We use cookies for essential functionality, and features such as maps and chat.
      </Typography>
      <Typography variant="body2" sx={{color: 'white'}}>
        To only allow the cookies that make the site work, click &apos;Essential cookies only.&apos;
      </Typography>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
