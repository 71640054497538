import {useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Typography} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

import {MAX_IMAGE_SIZE_MB, MAX_IMAGE_SIZE_BYTES, FILE_DROP_ACCEPT} from '../constants';

const FileDrop = ({
  initialValue,
  onFileUpdate,
  buttonID,
  uploadButtonVisible = true,
  style = {},
  fileDropAccept = {},
}) => {
  useEffect(() => {
    if (initialValue) {
      setCurrentImage(initialValue);
    }
  }, [initialValue]);
  const [currentFilename, setCurrentFilename] = useState();
  const [currentImage, setCurrentImage] = useState(null);
  const hasNonImage = currentImage && (currentImage?.indexOf('data:image/') ?? -1) !== 0;

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    maxSize: MAX_IMAGE_SIZE_BYTES,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setCurrentFilename(file.name);
        onFileUpdate(file);
        const reader = new FileReader();
        reader.onabort = () => console.error('file reading was aborted');
        reader.onerror = () => console.error('file reading has failed');
        reader.onload = ({target}) => {
          let {result} = target;
          setCurrentImage(result);
        };
        reader.readAsDataURL(file);
      }
    },
    onDropRejected: (fileRejections) => {
      if (fileRejections.length > 0) {
        const {file, errors} = fileRejections[0];
        if (file.size > MAX_IMAGE_SIZE_BYTES) {
          alert(
            `Your file is too large, the maximum filesize is ${MAX_IMAGE_SIZE_MB}MB. Please try again with a smaller image.`,
          );
        } else {
          const [error] = errors;
          if (error.code === 'file-invalid-type') {
            alert(`Your file type is not valid. Please try another file format.`);
          }
        }
      }
    },
    accept: {
      ...FILE_DROP_ACCEPT,
      ...fileDropAccept,
    },
  });
  const rootProps = getRootProps();

  let backgroundColor = '#F7F7F7';
  if (isDragActive) {
    backgroundColor = 'darkgray';
  } else if (currentImage) {
    backgroundColor = 'white';
  }

  return (
    <div style={style}>
      <div {...rootProps}>
        <input {...getInputProps()} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            height: 195,
            border: '1px dashed #A04791',
            backgroundColor,
          }}>
          {currentImage ? (
            hasNonImage ? (
              <Grid item sx={{textAlign: 'center'}}>
                <DescriptionIcon sx={{fontSize: 60, color: 'grey'}} />
                {currentFilename && <Typography>{currentFilename}</Typography>}
              </Grid>
            ) : (
              <div
                style={{
                  backgroundImage: `url(${currentImage})`,
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}></div>
            )
          ) : (
            <Grid item>
              <UploadFileIcon sx={{fontSize: 60}} color="primary" />
            </Grid>
          )}
        </Grid>
      </div>
      {uploadButtonVisible && (
        <Grid container direction="row" justifyContent="center" sx={{mt: 1}}>
          <Grid item xs={12} sm={4}>
            <Button
              id={buttonID}
              variant="contained"
              color="primary"
              fullWidth
              onClick={rootProps.onClick}>
              Upload
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default FileDrop;
