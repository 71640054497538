import {Wrapper, Status} from '@googlemaps/react-wrapper';
import {Alert, Button, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';

import {getNonEssentialCookieConsent, setNonEssentialCookieConsent} from '../util';
import Loading from './Loading';

export const MapWrapper = ({children, requireCookies = false}) => {
  const hasNonEssentialCookieConsent = getNonEssentialCookieConsent() === 'true';
  return requireCookies && !hasNonEssentialCookieConsent ? (
    <Alert severity="info" sx={{my: 4}}>
      <Typography paragraph>Maps are currently disabled</Typography>
      <Button
        variant="outlined"
        onClick={() => {
          setNonEssentialCookieConsent('true');
          window.location.reload();
        }}>
        Enable non-essential cookies
      </Button>
    </Alert>
  ) : (
    <Wrapper
      apiKey={process.env.GOOGLE_MAPS_API_KEY}
      render={(status) => {
        if (status === Status.FAILURE) return <div>Error</div>;
        return <Loading />;
      }}>
      {children}
    </Wrapper>
  );
};

const Marker = ({label, position, map, draggable, icon, onMove}) => {
  const [marker, setMarker] = useState();
  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({label, position, draggable, icon, map});
    }
  }, [marker, label, position, draggable, icon, map]);

  useEffect(() => {
    let listener;
    if (map && marker && onMove) {
      listener = window.google.maps.event.addListener(marker, 'dragend', (event) => {
        onMove(event.latLng.lat(), event.latLng.lng());
      });
    }
    return () => {
      window.google.maps.event.removeListener(listener);
    };
  }, [marker, map, onMove]);

  return null;
};

const Map = ({
  markerCoordinates,
  style = {
    height: 400,
  },
  zoom = 16,
  children,
  interactive,
}) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState();
  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(
        new window.google.maps.Map(mapRef.current, {
          zoom,
          center: markerCoordinates,
          disableDefaultUI: !interactive,
          gestureHandling: !interactive ? 'none' : undefined,
        }),
      );
    }
  }, [mapRef, map]);
  useEffect(() => {
    if (map) {
      map.setOptions({
        zoom,
        center: markerCoordinates,
        disableDefaultUI: !interactive,
        gestureHandling: !interactive ? 'none' : undefined,
      });
    }
  }, [map, interactive]);

  useEffect(() => {
    if (map) {
      map.panTo(markerCoordinates);
    }
  }, [markerCoordinates]);

  return (
    <>
      <div ref={mapRef} style={style} id="map" data-testid="google-map" />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {map});
        }
      })}
    </>
  );
};

const GoogleMap = ({
  markerCoordinates, // todo update format
  canMoveMarker,
  onMoveMarker,
  entrances = [],
  canMoveEntrances,
  onMoveEntrance,
  style,
  interactive = false,
}) => {
  return (
    <Map
      style={{
        ...style,
        pointerEvents: !interactive ? 'none' : undefined,
      }}
      interactive={interactive}
      markerCoordinates={markerCoordinates}>
      <Marker position={markerCoordinates} draggable={canMoveMarker} onMove={onMoveMarker} />
      {entrances?.map((entrance) => (
        <Marker
          key={entrance.id}
          label={entrance.name}
          position={{lat: entrance.latitude, lng: entrance.longitude}}
          icon={{
            url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
          }}
          draggable={canMoveEntrances}
          onMove={(lat, lng) => onMoveEntrance(entrance, lat, lng)}
        />
      ))}
    </Map>
  );
};

export default GoogleMap;
