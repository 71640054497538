import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {spaceBottom} from '../useSharedStyles';

const VenueEntrances = ({beacons}) => {
  return (
    <List dense sx={spaceBottom}>
      {beacons.map((beacon) => (
        <ListItem key={`beacon-${beacon.id}`}>
          <ListItemText primary={beacon.name} secondary={beacon.description} />
        </ListItem>
      ))}
    </List>
  );
};

export default VenueEntrances;
